import { Component } from "react";
import NavBar from "../components/NavBar";
import components from "../tools/components.tool";
import { Button, Col, DropdownItem, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import colors from "../config/colors.config";
import Table from "react-data-table-component";
import ClienteService from "../services/Cliente.service";
import imgFondo from "../assets/image/background/003.jpg"
import { Cached, Close, PictureAsPdf, Phone, Visibility, WhatsApp, Edit, ListAlt, MoreHoriz, Search, Add } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import { IconButton } from "@material-ui/core";
import numeroTools from "../tools/Numero.tool";
import timeTool from "../tools/time.tool";
import usuarioModel from "../services/User.service";
import QRCode from 'qrcode.react';
import solicitudCreditoModal from "../services/SolicitudesCredito.service";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SolicitudCreditoPDF from "../components/SolicitudCreditoPDF";
import { PuffLoader } from "react-spinners";
import solicitudCreditoModel from "../services/SolicitudCredito.service";
import imgEmpty from "../assets/image/img.jpg";
import SClient from "../models/Cliente.model";

const concatenarNombreCompleto = (row) => {
    return row.nombreCliente + " " + row.apellidoPaternoCliente + " " + row.apellidoMaternoCliente
}

const concatenarLugarDondeVive = (row) => {
    return row.calle_y_numeroCasaCliente + ", " + row.nombreLocalidadVivienda + ", " + row.nombreCiudadVivienda + ", " + row.nombreEstadoVivienda + ", " + row.nombrePaisVivienda
}

class ListaClientes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledButtonGuardarSC: true,
            listaTipoVivienda: [],
            listaFormasPago: [],
            listaMontoPago: [],
            listaPlazoPagoCredito: [],
            estaAbiertoModalNuevoSolicitudCredito: false,
            estaCargandoTabla: true,
            textoReferenciaCliente: "",
            estaAbiertoModalHistorialSolicitudCredito: false,
            estaAbiertoModalBotones: false,
            estaAbiertoModelSolicitudcredito: false,
            estaAbiertoModalPdf: false,
            estaAbiertoModalListadoSolicitudCreditoCliente: false,
            estaAbiertoModalLlamada: false,
            estaAbiertoModalCliente: false,
            estaAbiertoModalOpcinesCliente: false,
            listaClientes: [],
            ListaSolicitudesCredito: [],
            listaSolicitudCredito: [],
            solicitudCredito: {
                IdSolicitudCredito: "",
                IdCliente: "",
                IdTipoSolicitudCredito: "",
                IdMontotoSolicitudCredito: 0,
                monto: 0,
                montoAprobadoSolicitudCredito: 0,
                IdPlazoPagoSolicitudCredito: 0,
                plazoSolicitudCredito: "",
                IdFormaPagoSolicitudCredito: 0,
                formaPagoSolicitudCredito: "",
                IdStatusSolicitudCredito: 0,
                statusSolicitudCredito: "",
                fechaCreacionSolicitudCredito: "",
                horaCreacionSolicitudCredito: "",
                fechaAprobacionRechazadoModificacion: "",
                horaAprobacionRechazadoModificacion: "",
            },
            cliente: SClient.getDataGenericClient(),
            columnsTablaHistorialSolicitudCredito: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Fecha y Hora</p>,
                    selector: row =>
                        <label
                            title={timeTool.castFechaNacimientoEscrita(row.fecha) + " " + timeTool.formato12Horas(row.hora)}
                        >
                            {timeTool.castFechaNacimiento(row.fecha) + " " + timeTool.formato12Horas(row.hora)}
                        </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Movimiento</p>,
                    selector: row =>
                        <label
                            title={row.descripcionTipoMovimientoSolicitudCredito + " por el usuario " + row.nombreCompletoUsuario + " (" + (row.username) + ")"}
                        >
                            {row.tipoMovimientoSolicitudCredito}
                        </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Cliente</p>,
                    selector: row =>
                        <label
                            title={row.nombreCompletoCliente}
                        >
                            {row.nombreCompletoCliente}
                        </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Referencia</p>,
                    selector: row =>
                        <label
                            title={row.nombreCompletoUsuario + " " + (row.username)}
                        >
                            {row.nombreCompletoUsuario} ({row.username})
                        </label>,
                    
                },
            ],
            columnsTablaSolicitudesCredito: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Fecha y Hora</p>,
                    selector: row => <label
                        title={"Creado el " + timeTool.castFechaNacimientoEscrita(row.fechaCreacionSolicitudCredito) + " " + timeTool.formato12Horas(row.horaCreacionSolicitudCredito)}
                    >
                        {timeTool.castFechaNacimiento(row.fechaCreacionSolicitudCredito) + " " + timeTool.formato12Horas(row.horaCreacionSolicitudCredito)}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Tipo Credito</p>,
                    selector: row => <label>
                        {row.IdTipoSolicitudCredito == 1 ? "Individual" : row.IdTipoSolicitudCredito == 2 ? "Grupal" : "########"}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Monto Solicitado</p>,
                    selector: row => <label>
                        {numeroTools.castFormatoMoneda(row.monto)}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Plazo</p>,
                    selector: row => <label>
                        {row.plazoSolicitudCredito}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Forma de Pago</p>,
                    selector: row => <label>
                        {row.formaPagoSolicitudCredito}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Estatus de La Solicitud</p>,
                    selector: row => <label>
                        {row.statusSolicitudCredito}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}></p>,
                    selector: row =>
                        <>
                            {
                                this.props.isDesktopDevice ?
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                this.listarHistorialSolicitudCredito(row.IdSolicitudCredito);
                                                this.setState({
                                                    estaAbiertoModalHistorialSolicitudCredito: true,
                                                    solicitudCredito: row,
                                                });
                                            }}
                                            title="Ver historial de la Solicitud de Credito"
                                            style={{
                                                backgroundColor: colors.blanco,
                                                color: colors.verde_1,
                                            }}
                                        >
                                            <ListAlt

                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    estaAbiertoModelSolicitudcredito: true,
                                                    solicitudCredito: row,
                                                });
                                            }}
                                            style={{
                                                backgroundColor: colors.blanco,
                                                color: colors.verde_1,
                                            }}
                                        >
                                            <Edit

                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={async () => {
                                                await this.setState({
                                                    estaAbiertoModalPdf: true,
                                                    solicitudCredito: row
                                                });
                                                await toast.success("PDF generado")
                                            }}
                                            title={"Generar PDF"}
                                        >
                                            <PictureAsPdf
                                                style={{
                                                    color: colors.rojoPDF
                                                }}
                                            />
                                        </IconButton>
                                    </>
                                    :
                                    <IconButton
                                        onClick={async () => {
                                            await this.setState({
                                                estaAbiertoModalBotones: true,
                                                solicitudCredito: row,
                                            });
                                        }}
                                    // title={"Generar PDF"}
                                    >
                                        <MoreHoriz
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>

                            }

                        </>,
                    
                },


            ],
            columnsTable: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre</p>,
                    selector: row => <label style={{ textDecoration: "none" }} title={concatenarNombreCompleto(row)} >{concatenarNombreCompleto(row)}</label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Lugar donde vive</p>,
                    selector: row => <label style={{ textDecoration: "none" }} title={concatenarLugarDondeVive(row)} >{concatenarLugarDondeVive(row)}</label>,
                    
                    width: "400px",
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>CURP</p>,
                    selector: row => <label style={{ textDecoration: "none" }} title={row.CURPCliente} >{row.CURPCliente}</label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>RFC</p>,
                    selector: row => <label style={{ textDecoration: "none" }} title={row.RFCCliente} >{row.RFCCliente}</label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Correo</p>,
                    selector: row => <label
                        style={{ textDecoration: "none", fontWeight: "bold", color: colors.azul_link, cursor: "pointer" }}
                        title={row.correoElectronicoCliente}
                        target="_blank"
                        onClick={async (e) => {
                            await navigator.clipboard.writeText(row.correoElectronicoCliente + "");
                            await window.open("https://tzedaka.com.mx:2096/", '_blank');
                        }}
                    // href={`https://tzedaka.com.mx:2096/`}
                    >
                        {row.correoElectronicoCliente}
                    </label>,
                    
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Teléfono</p>,
                    selector: row =>
                        <label
                            style={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: colors.azul_link,
                                cursor: "pointer"
                            }}
                            title={row.numeroTelefonoCliente}
                            onClick={() => {

                                let enlaceTel = 'tel:+52' + row.numeroTelefonoCliente;
                                try {
                                    window.location.href = enlaceTel;

                                } catch (error) {

                                }

                            }}
                        >
                            {row.numeroTelefonoCliente}
                        </label>,
                    
                },

                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.verde_1 }}>Numero Telefono</p>,
                //     selector: row =>
                //     <QRCode value={row.numeroTelefonoCliente} />,
                //     
                // },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}></p>,
                    selector: row =>
                        <IconButton
                            onClick={async () => {
                                await this.obtenerCliente(row.IdCliente);
                            }}
                        >
                            <Visibility
                                style={{
                                    color: colors.verde_1
                                }}
                            />
                        </IconButton>,
                    
                },
            ],
        }
    }

    async componentDidMount() {
        this.props.isDesktopDevice ?
            document.body.style = `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`

        usuarioModel.getTokenUsuario();

        this.listarClientes();
        components.fucusElement("textoReferenciaCliente");


        let response = await ClienteService.getListaFormaPago();
        let { listaFormasPago } = response;
        response = await ClienteService.getListaPlazoPagoCredito();
        let { listaPlazoPagoCredito } = response;
        response = await ClienteService.getListaMontoCredito();
        let { listaMontoPago } = response;

        await this.setState({
            listaFormasPago,
            listaPlazoPagoCredito,
            listaMontoPago
        });
    }

    obtenerCliente = async (IdCliente) => {

        let response = await ClienteService.getCliente(IdCliente);

        let { cliente } = response;

        await this.setState({
            estaAbiertoModalCliente: true,
            cliente,
        });
    }

    listarClientes = async () => {
        await this.setState({
            estaCargandoTabla: true,
        });
        let response = await ClienteService.getListaClientes();

        let { listaClientes } = response;

        await this.setState({
            listaClientes: response == null ? [] : listaClientes,
            estaCargandoTabla: false,
        });

        await toast.info("Se listaron " + listaClientes.length + " clientes");
    }

    listarSolicitudesCredito = async () => {
        let response = await solicitudCreditoModal.getSolicitudesCreditoPorIdCliente(this.state.cliente.IdCliente);
        let { ListaSolicitudesCredito } = response

        this.setState({
            estaAbiertoModalListadoSolicitudCreditoCliente: true,
            ListaSolicitudesCredito
        });
    }

    listarHistorialSolicitudCredito = async (IdSolicitudCredito) => {

        let response = await solicitudCreditoModal.listarHistorialSolicitudCredito(IdSolicitudCredito);

        let { status, listaSolicitudCredito } = response;
        if (status === 200) {
            this.setState({
                listaSolicitudCredito
            });
        } else {
            toast.error("Error al conectar con el servidor");
        }


    }

    buscarCliente = async () => {
        let { textoReferenciaCliente } = this.state;

        if (textoReferenciaCliente.length === 0) {
            toast.warn("No has especificado el nombre, apellidos del cliente a buscar");
            components.fucusElement("textoReferenciaCliente");
            return
        }
        await this.setState({
            estaCargandoTabla: true,
        });
        let response = await ClienteService.buscarCliente(textoReferenciaCliente);
        let { status, listaClientes } = response;
        if (status === 200) {
            this.setState({
                listaClientes,
                estaCargandoTabla: false,
            });
        } else {
            toast.error("Error al conectar con el servidor");
            await this.setState({
                estaCargandoTabla: false,
            });
        }

    }

    cambioSolicitudCredito = async ({ target }) => {
        let { name, value } = target;
        console.log({ name, value });
        await this.setState({
            solicitudCredito: {
                ...this.state.solicitudCredito,
                [name]: value,
            }
        });

        let disabledButtonGuardarSC = true;

        if (
            this.state.solicitudCredito.IdTipoSolicitudCredito == 0 ||
            this.state.solicitudCredito.IdMontotoSolicitudCredito == 0 ||
            this.state.solicitudCredito.IdPlazoPagoSolicitudCredito == 0 ||
            this.state.solicitudCredito.IdFormaPagoSolicitudCredito == 0
        ) {
            disabledButtonGuardarSC = true;
        } else {
            disabledButtonGuardarSC = false;
        }

        this.setState({
            disabledButtonGuardarSC
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />
                <div
                    style={{
                        marginTop: this.props.isDesktopDevice ? "10vh" : "20px",
                        backgroundColor: colors.blanco,
                        marginRight: this.props.isDesktopDevice ? "50px" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "50px" : "5px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ height: "3vh" }} ></div>
                    <Row style={{ marginRight: 5, marginLeft: 5, }}  >
                        <Col >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 30,
                                    color: colors.azul,
                                }}
                            >
                                {this.props.isDesktopDevice ? "Lista de Clientes" : "Clientes"}
                            </Label>
                        </Col>
                        <Col
                            style={{
                                textAlign: "right",
                                marginRight: "15px"
                            }}
                        >
                            <IconButton
                                style={{ backgroundColor: "#F2F2F2", color: colors.azul }}
                                onClick={() => {
                                    this.listarClientes();
                                    toast.success("Tabla actualizada");
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>

                    <Row style={{ marginRight: 5, marginLeft: 5, }}  >
                        <Col
                            style={{
                                maxWidth: this.props.isDesktopDevice ? "50%" : "100%",
                            }}
                        >
                            <FormGroup>
                                {/* <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        color: colors.verde_1,
                                    }}
                                >
                                    Buscar Cliente
                                </Label> */}
                                <InputGroup>

                                    <Input
                                        id="textoReferenciaCliente"
                                        placeholder="Buscar por nombre, apellido paterno, apellido materno"
                                        value={this.state.textoReferenciaCliente}
                                        style={{
                                            boxShadow: "none",
                                            borderColor: colors.azul
                                        }}
                                        onChange={({ target }) => {
                                            let { value } = target;
                                            value = value.replace(/\s+/g, ' ');
                                            value = value.replace(/[^a-zA-Z0-9\s]/g, '');
                                            this.setState({
                                                textoReferenciaCliente: value
                                            });
                                        }}
                                        onKeyDown={({ key }) => {
                                            if (key === "Enter") {
                                                this.buscarCliente();
                                            }
                                        }}
                                    />
                                    <InputGroupText
                                        style={{
                                            backgroundColor: colors.azul,
                                            color: colors.blanco,
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            this.buscarCliente();
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: "5px",
                            marginLeft: "5px",
                            //marginTop: "20px",
                            borderRadius: "8px",
                            overflowY: "scroll",
                            height: "68vh"
                        }}

                    >
                        {
                            this.state.estaCargandoTabla ?
                                <div
                                    style={{
                                        marginTop: "100px",

                                    }}
                                >
                                    <center>
                                        <PuffLoader size={250} color="#36d7b7" />
                                    </center>
                                </div>
                                :
                                <Table
                                    columns={this.state.columnsTable}
                                    data={this.state.listaClientes}
                                    // title={<label style={{ color: colors.verde_1, fontWeight: "bold" }} >{`Hay ${this.state.listaClientes.length} clientes encontrados`}   </label>}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>No hay clientes registrados</p>}
                                    // pagination
                                    dense
                                />
                        }

                    </Row>
                </div>

                <Modal
                    isOpen={this.state.estaAbiertoModalCliente}
                    size="lg"
                    style={
                        this.props.isDesktopDevice ?
                            {
                                maxWidth: "96%"
                            }
                            :
                            {}
                    }
                >
                    <ModalHeader
                        close={
                            <Row>
                                <Col
                                    style={{

                                    }}
                                >
                                    {/* <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalOpcinesCliente: true,
                                            });
                                        }}
                                        title="Mostrar mas opciones"
                                    >
                                        <MoreVert
                                            style={{
                                                color: colors.azul,
                                            }}
                                        />
                                    </IconButton> */}
                                    <IconButton
                                        style={{
                                            backgroundColor: "#F2F2F2", color: colors.azul, marginRight: "7px",
                                        }}
                                        onClick={async () => {
                                            // https://wa.me/whatsappphonenumber/?text=urlencodedtext
                                            await window.open(`https://wa.me/+52${this.state.cliente.numeroTelefonoCliente}/?text=urlencodedtext`, '_blank');
                                        }}
                                    >
                                        <WhatsApp
                                            style={{
                                                // color: "#1AD03F"
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            marginRight: "7px",
                                            backgroundColor: "#F2F2F2", color: colors.azul
                                        }}


                                        onClick={() => {
                                            // https://wa.me/whatsappphonenumber/?text=urlencodedtext
                                            this.setState({
                                                estaAbiertoModalLlamada: true
                                            });
                                        }}
                                    >
                                        <Phone
                                            style={{
                                                // color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            backgroundColor: "#F2F2F2", color: colors.azul
                                        }}
                                        onClick={async () => {
                                            let { cliente } = this.state;
                                            if (this.state.textoReferenciaCliente.length === 0) {
                                                await this.obtenerCliente(cliente.IdCliente);
                                            } else {
                                                await this.buscarCliente(this.state.textoReferenciaCliente);
                                            }

                                            await toast.success("Datos actualizados");
                                        }}
                                        title="Volver a cargar los datos del cliente"
                                    >
                                        <Cached
                                            style={{
                                                // color: colors.verde_1,
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            backgroundColor: "#F2F2F2", color: colors.azul
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalCliente: false,
                                                cliente: SClient.getDataGenericClient()
                                            });
                                        }}
                                        title="Cerrar ventana"
                                    >
                                        <Close
                                            style={{
                                                // color: colors.verde_1,
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                        style={{
                            backgroundColor: colors.azul,
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.blanco,
                                fontSize: 28
                            }}
                        >
                            {this.props.isDesktopDevice ? "Datos del Cliente" : "Datos"}
                        </Label>
                    </ModalHeader>
                    <ModalBody
                        style={{
                            overflowY: "scroll",
                            height: "70vh"
                        }}
                    >

                        <Row
                            style={{
                                marginTop: "20px"
                            }}
                        >
                            {/* <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                
                            </Col> */}
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        color: colors.azul,
                                    }}
                                >
                                    Datos Generales
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                // marginRight:"2px",
                                // marginLeft:"2px",
                            }}
                        >
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Nombre completo
                                </Label>
                                <br />
                                <Label

                                >
                                    {concatenarNombreCompleto(this.state.cliente)}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Numero Teléfono
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.numeroTelefonoCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Correo electrónico
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.correoElectronicoCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    País de Nacimiento
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombrePaisNacimientoCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Estado de Nacimiento
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreEstadoNacimientoCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Ciudad de Nacimiento
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreCiudadNacimientoCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Estado Civil
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreEstadoCivilCliente}
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Clave elector
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.claveElectorCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    INE Folio
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.INEFolioCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    INE Numero
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.INENumeroCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    CURP
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.CURPCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    RFC
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.RFCCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    País Donde Vives
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombrePaisViviendaCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Estado Donde Vives
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreEstadoViviendaCliente}
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}

                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Ciudad Donde Vives
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreCiudadViviendaCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}

                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Localidad Donde Vives
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreLocalidadCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Calle y Numero de Casa
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.calle_y_numeroCasaCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Tipo de Vivienda
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.descripcionTipoViviendaCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                                style={{
                                    maxWidth: "16%"
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Nombre del Cónyuge
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.nombreConyugueCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Dependientes Económicos
                                </Label>
                                <br />
                                <Label

                                >
                                    {this.state.cliente.dependientesEconimicosCliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Renta
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosRentaCasa == undefined ? "0.00" : this.state.cliente.gastosRentaCasa)}

                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Educación
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosEducacionCasa == undefined ? "0.00" : this.state.cliente.gastosEducacionCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Salud
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosSaludCasa == undefined ? "0.00" : this.state.cliente.gastosSaludCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Agua
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosAguaCasa == undefined ? "0.00" : this.state.cliente.gastosAguaCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Luz
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosLuzCasa == undefined ? "0.00" : this.state.cliente.gastosLuzCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Gastos de Internet
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosInternetCasa == undefined ? "0.00" : this.state.cliente.gastosInternetCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Otros Gastos
                                </Label>
                                <br />
                                <Label

                                >
                                    {numeroTools.castFormatoMoneda(this.state.cliente.gastosOtrosCasa == undefined ? "0.00" : this.state.cliente.gastosOtrosCasa)}

                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Fecha de Nacimiento
                                </Label>
                                <br />
                                <Label
                                    title={timeTool.castFechaNacimientoEscrita(this.state.cliente.fechaNacimientoCliente == undefined ? "" : this.state.cliente.fechaNacimientoCliente)}
                                >
                                    {timeTool.castFechaNacimiento(this.state.cliente.fechaNacimientoCliente == undefined ? "" : this.state.cliente.fechaNacimientoCliente)} ({timeTool.calcularEdad(this.state.cliente.fechaNacimientoCliente == undefined ? "" : this.state.cliente.fechaNacimientoCliente)})

                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Religion que Profesa
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.religion}
                                </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        {/* {this.state.cliente.tieneNegocioCliente} {this.state.cliente.tieneNegocioCliente == 1 ? "True":"Flase"}  {this.state.cliente.esTrabajadorCliente} */}
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        color: colors.azul,
                                    }}
                                >
                                    {/* {this.state.cliente.esTrabajadorCliente} {this.state.cliente.tieneNegocioCliente} */}
                                    {
                                        this.state.cliente.tieneNegocioCliente == 1 ?
                                            "Datos del Negocio"
                                            :
                                            "Datos del Empleo"
                                    }


                                </Label>
                            </Col>
                        </Row>
                        {
                            this.state.cliente.esTrabajadorCliente == 0 && this.state.cliente.tieneNegocioCliente == 0 ?
                                null
                                :
                                <>
                                    <Row>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Categoría
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {this.state.cliente.puestoCategoriaTrabajoCliente}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Teléfono
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {this.state.cliente.numeroTelefonoNegocioCliente}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Dirección
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {this.state.cliente.direccionTrabajoCliente}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Punto de Referencia
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {this.state.cliente.puntpReferenciaTrabajoCliente}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Tiempo con tu Negocio
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {this.state.cliente.antiguedadTrabajoCliente}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Ingreso Mensual
                                            </Label>
                                            <br />
                                            <Label

                                            >
                                                {numeroTools.castFormatoMoneda(this.state.cliente.ingresoMensualTrabajoCliente == undefined ? "0.00" : this.state.cliente.ingresoMensualTrabajoCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Dias que Trabaja
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.trabajaLunesNegocioCliente == undefined ? "" : this.state.cliente.trabajaLunesNegocioCliente == 1 ? "Lunes" : ""}
                                                {this.state.cliente.trabajaMartesNegocioCliente == undefined ? "" : this.state.cliente.trabajaMartesNegocioCliente == 1 ? ", Martes" : ""}
                                                {this.state.cliente.trabajaMiercolesNegocioCliente == undefined ? "" : this.state.cliente.trabajaMiercolesNegocioCliente == 1 ? ", Miercoles" : ""}
                                                {this.state.cliente.trabajaJuevesNegocioCliente == undefined ? "" : this.state.cliente.trabajaJuevesNegocioCliente == 1 ? ", Jueves" : ""}
                                                {this.state.cliente.trabajaViernesNegocioCliente == undefined ? "" : this.state.cliente.trabajaViernesNegocioCliente == 1 ? ", Viernes" : ""}
                                                {this.state.cliente.trabajaSabadoNegocioCliente == undefined ? "" : this.state.cliente.trabajaSabadoNegocioCliente == 1 ? ", Sabado" : ""}
                                                {this.state.cliente.trabajaDomingoNegocioCliente == undefined ? "" : this.state.cliente.trabajaDomingoNegocioCliente == 1 ? ", Domingo" : ""}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Horario de Trabajo
                                            </Label>
                                            <br />
                                            <Label>
                                                {timeTool.formato12Horas(this.state.cliente.horaEntradaTrabajoNegocio == undefined ? "00:00:00" : this.state.cliente.horaEntradaTrabajoNegocio)} a {timeTool.formato12Horas(this.state.cliente.horaSalidaTrabajoNegocio == undefined ? "00:00:00" : this.state.cliente.horaSalidaTrabajoNegocio)}
                                            </Label>
                                        </Col>
                                    </Row>
                                </>
                        }


                        {
                            this.state.cliente.tieneNegocioCliente === 1 ?
                                <>
                                    <DropdownItem divider />
                                    <Row>
                                        <Col>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "22px",
                                                    color: colors.azul,
                                                }}
                                            >
                                                Análisis del Negocio
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Ventas Semanales
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.ventasSemanalesNegocioCliente == undefined ? "0.00" : this.state.cliente.ventasSemanalesNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Inversion Semanal
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.inversionSemanalNegocioCliente == undefined ? "0.00" : this.state.cliente.inversionSemanalNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Tipo de Local
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.descripcionLocalPropioRentado}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Gasto de Renta
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosRentaNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosRentaNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Gasto de Agua
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosAguaNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosAguaNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Gasto de Luz
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosLuzNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosLuzNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Gasto de Internet
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosInternetNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosInternetNegocioCliente)}
                                            </Label>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Otros Gastos
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosOtrosNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosOtrosNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Otros Gastos
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.gastosOtrosNegocioCliente == undefined ? "0.00" : this.state.cliente.gastosOtrosNegocioCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Tiene en Mercancía
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.cuantoTieneMercanciaCliente == undefined ? "0.00" : this.state.cliente.cuantoTieneMercanciaCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Dinero que le Deben
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.cuantoDineroDebenCliente == undefined ? "0.00" : this.state.cliente.cuantoDineroDebenCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Dinero Efectivo/Ahorrado
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.cuantoTieneEfectivoAhorradoCliente == undefined ? "0.00" : this.state.cliente.cuantoTieneEfectivoAhorradoCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Ganancial del Negocio
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.cuantoTieneGananciaNegocio == undefined ? "0.00" : this.state.cliente.cuantoTieneGananciaNegocio)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Tiene Otro Ingreso
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.tieneOtrosIngresosCliente === 1 ? "Si" : "No"}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Descripción Otro Ingreso
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.descripcionOtrosIngresosClientes}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Declaración Patrimonial Vivienda
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.viviendaDeclaracionPatrimonialCliente == undefined ? "0.00" : this.state.cliente.viviendaDeclaracionPatrimonialCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Declaración Patrimonial Vehículos
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.vehiculosDeclaracionPatrimonialCliente == undefined ? "0.00" : this.state.cliente.vehiculosDeclaracionPatrimonialCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Declaración Patrimonial Equipo
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.mobiliarioEquipoDeclaracionPatrimonial == undefined ? "0.00" : this.state.cliente.mobiliarioEquipoDeclaracionPatrimonial)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Otros Declaracion Patrimonial
                                            </Label>
                                            <br />
                                            <Label>
                                                {numeroTools.castFormatoMoneda(this.state.cliente.otrosDeclaracionPatrimonialCliente == undefined ? "0.00" : this.state.cliente.otrosDeclaracionPatrimonialCliente)}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Ha Tenido Créditos
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.haTenidoCreditoBancariosCliente === 1 ? "Si" : "No"}
                                            </Label>
                                        </Col>
                                        <Col
                                            md={this.props.isDesktopDevice ? "" : 2}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul
                                                }}
                                            >
                                                Institución Donde le Dieron el Crédito
                                            </Label>
                                            <br />
                                            <Label>
                                                {this.state.cliente.institucionDieronCreditoCliente}
                                            </Label>
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }
                        <DropdownItem divider />
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "22px",
                                        color: colors.azul,
                                    }}
                                >
                                    Referencia De Personas Que No Viven Con El Cliente
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Nombre Completo y Apellido
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.nombreCompletoPersonaReferencia1Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Parentesco
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.parentescoPersonaReferencia1Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Dirección
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.direccionReferencia1Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Numero de Teléfono
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.numeroTelefonoPersonaReferencia1Cliente}
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Nombre Completo y Apellido
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.nombreCompletoPersonaReferencia2Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Parentesco
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.parentescoPersonaReferencia2Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Dirección
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.direccionReferencia2Cliente}
                                </Label>
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Numero de Teléfono
                                </Label>
                                <br />
                                <Label>
                                    {this.state.cliente.numeroTelefonoPersonaReferencia2Cliente}
                                </Label>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Foto de Fachada de la Casa
                                </Label>
                                <br />
                                <img
                                    src={this.state.cliente.imgFachadaCasa === undefined ? imgEmpty : this.state.cliente.imgFachadaCasa}
                                    alt="No se pudo cargar la imagen"
                                    width={this.state.cliente.imgFachadaCasa=== undefined ? "100%" : "50%"}
                                />
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Foto con el Cliente
                                </Label>
                                <br />
                                <img
                                    src={this.state.cliente.imgFotoPersona === undefined ? imgEmpty : this.state.cliente.imgFotoPersona}
                                    alt="No se pudo cargar la imagen"
                                    width={this.state.cliente.imgFotoPersona === undefined ? "100%" : "50%"}
                                />
                            </Col>
                            <Col
                                md={this.props.isDesktopDevice ? "" : 2}
                            >
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul
                                    }}
                                >
                                    Foto del INE
                                </Label>
                                <br />
                                <img
                                    src={this.state.cliente.imgINE.length === undefined ? imgEmpty : this.state.cliente.imgINE}
                                    alt="No se pudo cargar la imagen"
                                    width={this.state.cliente.imgINE.length === undefined ? "100%" : "50%"}
                                />
                            </Col>

                        </Row> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                width: this.props.isDesktopDevice ? "200px" : "100%",
                                fontWeight: "bold",
                                backgroundColor: colors.verde_1,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalCliente: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            style={{
                                width: this.props.isDesktopDevice ? "200px" : "100%",
                                backgroundColor: colors.verde_1,
                                fontWeight: "bold",
                            }}
                            onClick={async () => {
                                this.listarSolicitudesCredito();
                            }}
                        >
                            Ver Solicitudes
                        </Button>
                    </ModalFooter>
                </Modal>


                <Modal
                    isOpen={this.state.estaAbiertoModalLlamada}
                    size="xl"
                >
                    <ModalHeader>
                        Números de Teléfonos
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <center>
                                            <QRCode value={"tel:" + this.state.cliente.numeroTelefonoCliente} />
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <a
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}
                                                href={`tel:+52${this.state.cliente.numeroTelefonoCliente}`}
                                            >
                                                {this.state.cliente.numeroTelefonoCliente}
                                            </a>

                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {concatenarNombreCompleto(this.state.cliente)}
                                                <br />
                                                Cliente
                                            </Label>
                                        </center>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <center>
                                            <QRCode value={"tel:" + this.state.cliente.numeroTelefonoPersonaReferencia1Cliente} />
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <a
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}
                                                href={`tel:+52${"tel:" + this.state.cliente.numeroTelefonoPersonaReferencia1Cliente}`}
                                            >
                                                {this.state.cliente.numeroTelefonoPersonaReferencia1Cliente}
                                            </a>

                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {this.state.cliente.nombreCompletoPersonaReferencia1Cliente}
                                                <br />
                                                persona Referencia
                                            </Label>
                                        </center>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <center>
                                            <QRCode value={"tel:" + this.state.cliente.numeroTelefonoPersonaReferencia2Cliente} />
                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <a
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "22px"
                                                }}
                                                href={`tel:+52${this.state.cliente.numeroTelefonoPersonaReferencia2Cliente}`}
                                            >
                                                {this.state.cliente.numeroTelefonoPersonaReferencia2Cliente}
                                            </a>

                                        </center>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <center>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {this.state.cliente.nombreCompletoPersonaReferencia2Cliente}
                                                <br />
                                                persona Referencia
                                            </Label>
                                        </center>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                // width: this.props.isDesktopDevice ? "200px" : "100%",
                                backgroundColor: colors.azul,
                                fontWeight: "bold",
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalLlamada: false,
                                });
                            }}
                        >
                            okey
                        </Button>
                    </ModalFooter>

                </Modal>

                <Modal
                    isOpen={this.state.estaAbiertoModalListadoSolicitudCreditoCliente}
                    size="lg"
                    style={
                        this.props.isDesktopDevice ?
                            {
                                maxWidth: "96%"
                            }
                            :
                            {
                                maxWidth: "96%"
                            }
                    }
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul
                        }}
                        close={
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            color: colors.azul, backgroundColor: "#F2F2F2", 
                                        }}
                                        onClick={async () => {
                                            this.setState({
                                                estaAbiertoModalNuevoSolicitudCredito: true,
                                            });
                                        }}
                                        title="Nueva Solicitud de Credito"
                                    >
                                        <Add
                                            style={{

                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            backgroundColor: "#F2F2F2", color: colors.azul
                                        }}
                                        onClick={async () => {
                                            this.listarSolicitudesCredito();
                                            this.setState({
                                                estaAbiertoModalListadoSolicitudCreditoCliente: true,
                                            });
                                        }}
                                        title="Actualizar tabla"
                                    >
                                        <Cached
                                            style={{
                                                color: colors.azul,
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            backgroundColor: "#F2F2F2", color: colors.azul
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalListadoSolicitudCreditoCliente: false,
                                            });
                                        }}
                                        title="Cerrar ventana"
                                    >
                                        <Close
                                            style={{
                                                color: colors.azul,
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.blanco,
                                fontSize: 28
                            }}
                        >
                            Solicitudes de Crédito de {concatenarNombreCompleto(this.state.cliente)}
                        </Label>

                    </ModalHeader>
                    <ModalBody>
                        <Table
                            columns={this.state.columnsTablaSolicitudesCredito}
                            data={this.state.ListaSolicitudesCredito}
                            // title={<label style={{ color: colors.verde_1, fontWeight: "bold" }} >{`Hay ${this.state.listaClientes.length} clientes encontrados`}   </label>}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay solicitudes de crédito pendientes registrados</p>}
                            pagination
                            dense
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                width: this.props.isDesktopDevice ? "150px" : "100%",
                                fontWeight: "bold",
                                backgroundColor: colors.azul,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalListadoSolicitudCreditoCliente: false,
                                    estaAbiertoModalCliente: false
                                });
                            }}
                        >
                            Cerrar Todo
                        </Button>
                        <Button
                            style={{
                                width: this.props.isDesktopDevice ? "150px" : "100%",
                                fontWeight: "bold",
                                backgroundColor: colors.azul,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalListadoSolicitudCreditoCliente: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>



                <Modal
                    isOpen={this.state.estaAbiertoModalPdf}
                    // size="lg"
                    // style={{
                    //     maxWidth: "90%"
                    // }}                    
                    fullscreen
                >
                    {/* <ModalHeader
                        toggle={() => {
                            this.setState({
                                estaAbiertoModalPdf: false
                            });
                        }}
                        style={{
                            backgroundColor: colors.blanco,
                            color: colors.verde_1,
                            fontWeight: "bold",
                        }}
                    >
                        <Label
                            style={{
                                fontSize: "24px"
                            }}
                        >
                            Solicitud de Crédito de {concatenarNombreCompleto(this.state.cliente)}
                        </Label>
                    </ModalHeader> */}
                    <ModalBody
                        style={{
                            backgroundColor: this.props.isDesktopDevice ? "#323639" : colors.blanco,
                            height: "100vh"
                        }}
                    >
                        <Row>

                            <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        this.setState({
                                            estaAbiertoModalPdf: false
                                        });
                                    }}
                                >
                                    <Close style={{ color: colors.blanco }} />
                                </IconButton>
                            </Col>
                        </Row>
                        {
                            this.props.isDesktopDevice ?
                                <PDFViewer
                                    style={{
                                        width: "100%",
                                        height: "90vh"
                                    }}
                                    showToolbar
                                >
                                    <SolicitudCreditoPDF cliente={this.state.cliente} solicitudCredito={this.state.solicitudCredito} />
                                </PDFViewer>
                                :
                                <Row
                                    style={{
                                        marginTop: "50%"
                                    }}
                                >
                                    <Col>
                                        <center>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.azulDescarga,
                                                    boxShadow: "none",
                                                    borderColor: colors.azulDescarga,

                                                }}
                                            >
                                                <PDFDownloadLink
                                                    document={<SolicitudCreditoPDF cliente={this.state.cliente} solicitudCredito={this.state.solicitudCredito} />}
                                                    fileName={`Solicitud Credito ${concatenarNombreCompleto(this.state.cliente)}.pdf`}
                                                    style={{
                                                        color: colors.blanco,
                                                        fontWeight: "bold",
                                                        textDecoration: "none",

                                                    }}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Descargando document...' : 'Descargar ahora!')}
                                                </PDFDownloadLink>
                                            </Button>
                                        </center>
                                    </Col>
                                </Row>

                        }


                    </ModalBody>
                    {/* <ModalFooter
                        style={{
                            // backgroundColor: "#525659",
                        }}
                    >
                        <Button
                            style={{
                                width: this.props.isDesktopDevice ? "150px" : "100%",
                                fontWeight: "bold",
                                backgroundColor: colors.azul,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalListadoSolicitudCreditoCliente: false,
                                    estaAbiertoModalCliente: false,
                                    estaAbiertoModalPdf: false
                                });
                            }}
                        >
                            Cerrar Todo
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalPdf: false
                                });
                            }}
                            style={{
                                width: this.props.isDesktopDevice ? "150px" : "100%",
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold"
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter> */}
                </Modal>
                <Modal
                    isOpen={this.state.estaAbiertoModelSolicitudcredito}
                // size="lg"
                >
                    <ModalHeader>
                        Solicitud de Credito
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Solicitante
                                    </Label>
                                    <Input
                                        type="text"
                                        id="concatenarNombreCompleto"
                                        value={concatenarNombreCompleto(this.state.cliente)}
                                    >
                                    </Input>
                                </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Estatus de La Solicitud
                                    </Label>
                                    <Input
                                        type="select"
                                        value={this.state.solicitudCredito.IdStatusSolicitudCredito}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            await this.setState({
                                                solicitudCredito: {
                                                    ...this.state.solicitudCredito,
                                                    IdStatusSolicitudCredito: value
                                                }
                                            });
                                            if (value == 2) {
                                                await components.fucusElement("montoAprobadoSolicitudCredito");
                                            }

                                        }}
                                    >
                                        <option value={1}>Inicial</option>
                                        <option value={2}>Aprobado</option>
                                        <option value={3}>Rechazado</option>
                                        <option value={4}>Cancelado</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Monto solicitado
                                    </Label>
                                    <Input
                                        type="text"
                                        id="monto"
                                        value={numeroTools.castFormatoMoneda(this.state.solicitudCredito.monto)}
                                    >
                                    </Input>
                                </FormGroup>

                            </Col>
                        </Row>
                        {
                            this.state.solicitudCredito.IdStatusSolicitudCredito == 2 ?

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>
                                                Monto aprobado
                                            </Label>
                                            <Input
                                                type="text"
                                                id="montoAprobadoSolicitudCredito"
                                                value={this.state.solicitudCredito.montoAprobadoSolicitudCredito}
                                                invalid={this.state.solicitudCredito.montoAprobadoSolicitudCredito > 20000 ? true : false}
                                                disable={this.state.solicitudCredito.IdStatusSolicitudCredito == 2 ? false : true}
                                                onChange={(event) => {
                                                    let { value } = event.target;

                                                    if (!isNaN(value) && value.length <= 5 && value.charAt(value.length - 1) !== " " && value.charAt(value.length - 1) !== "-" && value.charAt(value.length - 1) !== "+" && value.charAt(value.length - 1) !== ".") {
                                                        this.setState({
                                                            solicitudCredito: {
                                                                ...this.state.solicitudCredito,
                                                                montoAprobadoSolicitudCredito: value
                                                            }
                                                        });
                                                    }

                                                }}
                                            >
                                            </Input>
                                            <FormFeedback>
                                                El monto máximo a aprobar es de $20,000.00 MXN
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.verde_1,
                                fontWeight: "bold"
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModelSolicitudcredito: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            disabled={this.state.solicitudCredito.montoAprobadoSolicitudCredito > 20000 ? true : false}
                            style={{
                                backgroundColor: colors.azul,
                                fontWeight: "bold"

                            }}
                            onClick={async () => {

                                let data = {
                                    IdSolicitudCredito: await this.state.solicitudCredito.IdSolicitudCredito,
                                    IdStatusSolicitudCredito: await this.state.solicitudCredito.IdStatusSolicitudCredito,
                                    montoAprobadoSolicitudCredito: await this.state.solicitudCredito.montoAprobadoSolicitudCredito,
                                };

                                await solicitudCreditoModal.solicitudCreditoAprobarRechazarCancelar(data);
                                await this.listarSolicitudesCredito();
                                await this.setState({
                                    estaAbiertoModelSolicitudcredito: false,
                                });
                                toast.success("Solicitud Credito Guardada");
                            }}
                        >
                            Guardar
                        </Button>


                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.estaAbiertoModalBotones}
                >
                    <ModalHeader
                        close={
                            <Row>
                                <Col>
                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalBotones: false,
                                            });
                                        }}
                                    >
                                        <Close
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                    >

                    </ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <center>
                                    <IconButton
                                        onClick={() => {
                                            this.listarHistorialSolicitudCredito(this.state.solicitudCredito.IdSolicitudCredito);
                                        }}
                                        title="Ver historial de la Solicitud de Credito"
                                    >
                                        <ListAlt
                                            style={{
                                                backgroundColor: colors.azul,
                                                color: colors.blanco,
                                            }}
                                        />
                                    </IconButton>
                                </center>
                            </Col>
                            <Col>
                                <center>
                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModelSolicitudcredito: true,
                                            });
                                        }}
                                    >
                                        <Edit
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                </center>
                            </Col>
                            <Col>
                                <center>
                                    <IconButton
                                        onClick={async () => {
                                            await this.setState({
                                                estaAbiertoModalPdf: true,
                                            });
                                            await toast.success("PDF generado")
                                        }}
                                        title={"Generar PDF"}
                                    >
                                        <PictureAsPdf
                                            style={{
                                                color: colors.rojoPDF
                                            }}
                                        />
                                    </IconButton>
                                </center>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.estaAbiertoModalNuevoSolicitudCredito}
                    size="md"
                    onOpened={() => {
                        components.fucusElement("IdTipoSolicitudCredito");
                        this.setState({
                            solicitudCredito: {
                                IdSolicitudCredito: "",
                                IdCliente: this.state.cliente.IdCliente,
                                IdTipoSolicitudCredito: "",
                                IdMontotoSolicitudCredito: 0,
                                monto: 0,
                                montoAprobadoSolicitudCredito: 0,
                                IdPlazoPagoSolicitudCredito: 0,
                                plazoSolicitudCredito: "",
                                IdFormaPagoSolicitudCredito: 0,
                                formaPagoSolicitudCredito: "",
                                IdStatusSolicitudCredito: 0,
                                statusSolicitudCredito: "",
                                fechaCreacionSolicitudCredito: "",
                                horaCreacionSolicitudCredito: "",
                                fechaAprobacionRechazadoModificacion: "",
                                horaAprobacionRechazadoModificacion: "",
                            }
                        });
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                style={{ backgroundColor: "#F2F2F2", color: colors.azul }}
                                onClick={() => {
                                    this.setState({
                                        estaAbiertoModalNuevoSolicitudCredito: false
                                    });
                                }}
                            >
                                <Close style={{ color: colors.azul }} />
                            </IconButton>
                        }
                        style={{
                            backgroundColor: colors.azul
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.blanco,
                                fontSize: 28
                            }}
                        >
                            Nueva Solicitud de Credito
                        </Label>

                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Nombre del Cliente
                            </Label>
                            <br />
                            <Label>
                                {this.state.cliente.nombreCliente} {this.state.cliente.apellidoMaternoCliente} {this.state.cliente.apellidoMaternoCliente}
                            </Label>
                        </Row>
                        <DropdownItem divider />
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Tipo de Crédito
                            </Label>
                            <Input
                                id="IdTipoSolicitudCredito"
                                name="IdTipoSolicitudCredito"
                                type="select"
                                value={this.state.solicitudCredito.IdTipoSolicitudCredito}
                                onChange={async (event) => {
                                    let { name, value } = event.target;
                                    console.log({ name, value });
                                    await this.cambioSolicitudCredito(event);
                                }}
                            >
                                <option value={0}>Selecciona una opción</option>
                                <option value={1}>Credito Individual</option>
                                <option value={2}>Credito Grupal</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Monto Solicitado
                            </Label>
                            <Input
                                id="IdMontotoSolicitudCredito"
                                name="IdMontotoSolicitudCredito"
                                type="select"
                                value={this.state.solicitudCredito.IdMontotoSolicitudCredito}
                                onChange={(event) => {
                                    this.cambioSolicitudCredito(event);
                                }}
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    this.state.listaMontoPago.map((value) => {
                                        return (
                                            <option value={value.IdMontoCredito}>{parseFloat(value.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 2 })} MXN</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Plazo del Crédito
                            </Label>
                            <Input
                                id="IdPlazoPagoSolicitudCredito"
                                name="IdPlazoPagoSolicitudCredito"
                                type="select"
                                value={this.state.solicitudCredito.IdPlazoPagoSolicitudCredito}
                                onChange={(event) => {
                                    this.cambioSolicitudCredito(event);
                                }}
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    this.state.listaPlazoPagoCredito.map((value) => {
                                        return (
                                            <option value={value.IdPlazoCredito}>{value.plazoCredito} ({value.descripcion})</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Forma de Pago
                            </Label>
                            <Input
                                id="IdFormaPagoSolicitudCredito"
                                name="IdFormaPagoSolicitudCredito"
                                type="select"
                                value={this.state.solicitudCredito.IdFormaPagoSolicitudCredito}
                                onChange={(event) => {
                                    this.cambioSolicitudCredito(event);
                                }}
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    this.state.listaFormasPago.map((formaPago) => {
                                        return (
                                            <option value={formaPago.IdFormaPago}>{formaPago.descripcion}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                        {
                            this.state.disabledButtonGuardarSC ?
                                <>
                                    <DropdownItem divider />
                                    <FormGroup
                                        style={{
                                            //backgroundColor: "#FFCE38",
                                            height: "auto",
                                            padding: 5
                                        }}
                                    >
                                        <Label
                                            style={{
                                                color: "#F5BC02",
                                                fontWeight: "bold",
                                                textShadow: "-1px -1px 0 #846901, 1px -1px 0 #846901, -1px 1px 0 #846901, 1px 1px 0 #846901",
                                            }}
                                        >
                                            Se tiene que llenar todos los campos para poder guardar la solicitud de crédito
                                        </Label>
                                    </FormGroup>
                                </>
                                :
                                null
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                boxShadow: "none",
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                            }}
                            disabled={this.state.disabledButtonGuardarSC}
                            onClick={async () => {
                                let { solicitudCredito } = this.state
                                await solicitudCreditoModel.guardarSolicitudCredito(solicitudCredito);
                                await this.listarSolicitudesCredito();
                                toast.success("Solicitud de Crédito Guardada");
                            }}
                        >
                            Guardar
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.estaAbiertoModalHistorialSolicitudCredito}
                    size="lg"
                    style={{
                        maxWidth: this.props.isDesktopDevice ? "96%" : "95%"
                    }}
                >
                    <ModalHeader
                        close={
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            this.listarHistorialSolicitudCredito(this.state.solicitudCredito.IdSolicitudCredito);
                                            toast.success("Tabla Actualizada")

                                        }}
                                    >
                                        <Cached
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton

                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalHistorialSolicitudCredito: false,
                                            });
                                        }}

                                    >
                                        <Close
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                        style={{
                            color: colors.verde_1,
                            fontWeight: "bold"
                        }}
                    >
                        Historial de la Solicitud de Crédito
                    </ModalHeader>
                    <ModalBody
                        style={{
                            overflowY: "scroll",
                            height: "550px"
                        }}
                    >
                        <ModalBody>
                            <Row>
                                <Table
                                    columns={this.state.columnsTablaHistorialSolicitudCredito}
                                    data={this.state.listaSolicitudCredito}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>No hay registros de esta solicitud de credito</p>}
                                />
                            </Row>
                        </ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.azul,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalHistorialSolicitudCredito: false,
                                });
                            }}
                        >
                            Okey
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default ListaClientes