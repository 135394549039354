import moment from "moment"

const calcularEdad = (fecha) => {

    let nacimiento = moment(fecha);
    let hoy = moment();
    let anios = hoy.diff(nacimiento, "years");

    if (anios.toString() == "NaN") {
        return "";
    } else {
        return anios + " años";
    }

}

const castFechaNacimiento = (fecha) => {
    fecha = fecha.substring(0, 10);
    fecha = fecha.split("-");
    fecha = fecha[2] + "/" + fecha[1] + "/" + fecha[0]
    return fecha;
}

const castFechaNacimientoEscrita = (date) => {

    if (date === "") return "Valor no acpetado";

    let fecha = new Date(parseInt(date.substring(0, 4)), parseInt(date.substring(5, 7)) - 1, parseInt(date.substring(8, 10)));
    // fecha = sumarDias(fecha,1)
    const dias = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
    ];
    let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const numeroDia = fecha.getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia + ", " + fecha.toLocaleDateString("es-ES", options); //+ ': ' + (numeroDia>=1 && numeroDia <= 5 ? "Avil":"Inavil");
}

const formato12Horas = (time) => {



    let meridiano = {
        1: "AM",
        2: "PM"
    }



    let tipoMeridiado = 1;
    let horas = parseInt(time.substring(0, 2));


    if (horas >= 13) {
        tipoMeridiado = 2;
        horas = horas - 12
    }

    if (horas < 10) {
        horas = "0" + horas

    }

    if (horas === 12) {
        tipoMeridiado = 2;
    }

    let minutos = time.substring(3, 5);
    let segundos = time.substring(6, 8);

    return horas + ':' + minutos + ':' + segundos + ' ' + meridiano[tipoMeridiado];
}

const getFechaHoy = () => {
    let fecha = new Date();
    fecha.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00.000

    // Obtenemos el año, mes y día.
    let year = fecha.getFullYear();
    let month = String(fecha.getMonth() + 1).padStart(2, '0');
    let day = String(fecha.getDate()).padStart(2, '0');

    // Formateamos la fecha en el formato "yyyy-mm-dd".
    let fechaFormateada = `${year}-${month}-${day}`;
    return fechaFormateada;
}

const timeTool = {
    calcularEdad,
    castFechaNacimiento,
    castFechaNacimientoEscrita,
    formato12Horas,
    getFechaHoy
}



export default timeTool;