import { Component } from "react";
import NavBar from "../../components/NavBar";
import colors from "../../config/colors.config.js";
import {
    Row,
    Col,
    Label,    
} from "reactstrap";
import img from "../../assets/image/background/004.jpg"

class Productos extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }
    componentDidMount() {
        this.props.isDesktopDevice ?
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`

    }

    render() {
        return (
            <>
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />

                

                <div
                    style={{
                        marginTop: "13%",
                        marginRight: this.props.isDesktopDevice ? "20%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "20%" : "5px",
                        backgroundColor: "rgba(255,255,255,0.65)",
                        borderRadius: "8px"
                    }}
                >

                    <Row >
                        <Col
                            style={{
                                marginTop: "50px",
                            }}
                        >
                            <center>
                                <Label
                                    style={{
                                        fontSize: "56px",
                                        color: colors.verde_1,

                                        fontWeight: "bold",

                                    }}
                                >
                                    TZEDAKA
                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "50px",
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: this.props.isDesktopDevice ? "25px" : "25px",
                                        color: colors.verde_1,
                                        // color:"#FBB23C",
                                        fontWeight: "bold",

                                    }}
                                >
                                    ¡ESTAMOS CONSTRUYENDO ALGO FANTÁSTICO!

                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: this.props.isDesktopDevice ? "30px" : "30px",
                            
                        }}
                    >

                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: "30px",
                                        color: colors.verde_1,
                                        // color:"#F6B855",
                                        fontWeight: "bold",
                                        marginBottom: "50px",

                                    }}
                                >
                                    Espéralo
                                </Label>
                            </center>
                        </Col>
                    </Row>

                </div>

            </>
        );
    }

}

export default Productos;