const getDataGenericClient = () => {
    let client = {
        IdCliente: 0,
        fechaNacimientoCliente: "",
        direccionReferencia1Cliente: "",
        direccionReferencia2Cliente: "",
        descripcionLocalPropioRentado: 0,
        nombreCliente: "",
        apellidoPaternoCliente: "",
        apellidoMaternoCliente: "",
        correoElectronicoCliente: "",
        IdPaisNacimientoCliente: 0,
        IdEstadoNacimientoCliente: 0,
        IdCiudadNacimientoCliente: 0,
        IdEstadoCivilCliente: 0,
        claveElectorCliente: "",
        INEFolioCliente: "",
        INENumeroCliente: "",
        CURPCliente: "",
        RFCCliente: "",
        IdPaisViviendaCliente: 0,
        IdEstadoViviendaCliente: 0,
        IdCiudadViviendaClietne: 0,
        IdLocalidadViviendaCliente: 0,
        dependientesEconimicosCliente: "",
        IdTipoViviendaCliente: 0,
        IdNivelEstudioCliente: 0,
        nombreConyugueCliente: "",
        numeroTelefonoCliente: "",
        gastoRentaViviendaCliente: 0,
        gastoInternetViviendaCliente: 0,
        gastoLuzViviendaCliente: 0,
        gastoEducacionViviendaCliente: 0,
        gastosSaludViviendaCliente: 0,
        gastosOtrosViviendaCliente: 0,
        esTrabajadorCliente: 0,
        tieneNegocioCliente: 0,
        nombreLugarTrabajoCliente: "",
        puestoCategoriaTrabajoCliente: "",
        numeroTelefonoTrabajoCliente: "",
        direccionTrabajoCliente: "",
        puntpReferenciaTrabajoCliente: "",
        antiguedadTrabajoCliente: "",
        ingresoMensualTrabajoCliente: 0,
        nombreNegocioCliente: "",
        actividadServicioNegocioCliente: "",
        ventasSemanalesNegocioCliente: 0,
        inversionSemanalNegocioCliente: 0,
        IdTipoLocalNegocioCliente: 0,
        gastosRentaNegocioCliente: 0,
        gastosAguaNegocioCliente: 0,
        gastosLuzNegocioCliente: 0,
        gastosInternetNegocioCliente: 0,
        gastosOtrosNegocioCliente: 0,
        mobiliarioEquipoNegocioCliente: 0,
        cuantoTieneMercanciaCliente: 0,
        cuantoDineroDebenCliente: 0,
        cuantoTieneEfectivoAhorradoCliente: 0,
        cuantoTieneGananciaNegocio: 0,
        trabajaLunesNegocioCliente: 0,
        trabajaMartesNegocioCliente: 0,
        trabajaMiercolesNegocioCliente: 0,
        trabajaJuevesNegocioCliente: 0,
        trabajaViernesNegocioCliente: 0,
        trabajaSabadoNegocioCliente: 0,
        trabajaDomingoNegocioCliente: 0,
        trabajaLunesTrabajoCliente: 0,
        trabajaMartesTrabajoCliente: 0,
        trabajaMiercolesTrabajoCliente: 0,
        trabajaJuevesTrabajoCliente: 0,
        trabajaViernesTrabajoCliente: 0,
        trabajaSabadoTrabajoCliente: 0,
        trabajaDomingoTrabajoCliente: 0,
        numeroTelefonoNegocioCliente: "",
        tieneOtrosIngresosCliente: 0,
        descripcionOtrosIngresosClientes: "",
        viviendaDeclaracionPatrimonialCliente: 0,
        vehiculosDeclaracionPatrimonialCliente: 0,
        mobiliarioEquipoDeclaracionPatrimonial: 0,
        otrosDeclaracionPatrimonialCliente: 0,
        haTenidoCreditoBancariosCliente: 0,
        institucionDieronCreditoCliente: "",
        montoCreditoCliente: 0,
        nombreCompletoPersonaReferencia1Cliente: "",
        parentescoPersonaReferencia1Cliente: "",
        numeroTelefonoPersonaReferencia1Cliente: "",
        nombreCompletoPersonaReferencia2Cliente: "",
        parentescoPersonaReferencia2Cliente: "",
        numeroTelefonoPersonaReferencia2Cliente: "",
        calle_y_numeroCasaCliente: "",
        gastosRentaCasa: 0,
        gastosEducacionCasa: 0,
        gastosSaludCasa: 0,
        gastosAguaCasa: 0,
        gastosLuzCasa: 0,
        gastosInternetCasa: 0,
        gastosOtrosCasa: 0,
        horaEntradaTrabajoNegocio: "",
        horaSalidaTrabajoNegocio: "",
        imgFotoPersona: "",
        imgFachadaCasa: "",
        imgINE: "",
        religion : "",
    }

    return client
}

const SClient = {
    getDataGenericClient
}


export default SClient 