import React, { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../config/colors.config";
import {
    Button,
    Col,
    DropdownItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import img from "../assets/image/background/003.jpg"
import imgEmpty from "../assets/image/img.jpg";
import AvisoPrivacidad from "../components/AvisoPrivacidad";
import localidadModel from "../services/Localidad.service";
import ClientServeci from "../services/Cliente.service";
import solicitudCreditoModel from "../services/SolicitudCredito.service";
import { IconButton } from '@material-ui/core';
import { Cached, CalendarToday, ThumbsUpDownOutlined } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import components from "../tools/components.tool";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagenTool from "../tools/Imagen.tool";

class SolicitudCredito extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: "",
            referenciaTrabajoSucursal: {
                0: "Datos del Empleo o Negocio",
                1: "Datos del Empleo",
                2: "Datos del Negocio",
            },
            i: 0,
            cliente: {
                IdCliente: 0,
                fechaNacimientoCliente: "",
                direccionReferencia1Cliente: "",
                direccionReferencia2Cliente: "",
                descripcionLocalPropioRentado: 0,
                nombreCliente: "",
                apellidoPaternoCliente: "",
                apellidoMaternoCliente: "",
                correoElectronicoCliente: "",
                IdPaisNacimientoCliente: 0,
                IdEstadoNacimientoCliente: 0,
                IdCiudadNacimientoCliente: 0,
                IdEstadoCivilCliente: 0,
                claveElectorCliente: "",
                INEFolioCliente: "",
                INENumeroCliente: "",
                CURPCliente: "",
                RFCCliente: "",
                IdPaisViviendaCliente: 0,
                IdEstadoViviendaCliente: 0,
                IdCiudadViviendaClietne: 0,
                IdLocalidadViviendaCliente: 0,
                dependientesEconimicosCliente: "",
                IdTipoViviendaCliente: 0,
                IdNivelEstudioCliente: 0,
                nombreConyugueCliente: "",
                numeroTelefonoCliente: "",
                gastoRentaViviendaCliente: 0,
                gastoInternetViviendaCliente: 0,
                gastoLuzViviendaCliente: 0,
                gastoEducacionViviendaCliente: 0,
                gastosSaludViviendaCliente: 0,
                gastosOtrosViviendaCliente: 0,
                esTrabajadorCliente: 0,
                tieneNegocioCliente: 0,
                nombreLugarTrabajoCliente: "",
                puestoCategoriaTrabajoCliente: "",
                numeroTelefonoTrabajoCliente: "",
                direccionTrabajoCliente: "",
                puntpReferenciaTrabajoCliente: "",
                antiguedadTrabajoCliente: "",
                ingresoMensualTrabajoCliente: 0,
                nombreNegocioCliente: "",
                actividadServicioNegocioCliente: "",
                ventasSemanalesNegocioCliente: 0,
                inversionSemanalNegocioCliente: 0,
                IdTipoLocalNegocioCliente: 0,
                gastosRentaNegocioCliente: 0,
                gastosAguaNegocioCliente: 0,
                gastosLuzNegocioCliente: 0,
                gastosInternetNegocioCliente: 0,
                gastosOtrosNegocioCliente: 0,
                mobiliarioEquipoNegocioCliente: 0,
                cuantoTieneMercanciaCliente: 0,
                cuantoDineroDebenCliente: 0,
                cuantoTieneEfectivoAhorradoCliente: 0,
                cuantoTieneGananciaNegocio: 0,
                trabajaLunesNegocioCliente: 0,
                trabajaMartesNegocioCliente: 0,
                trabajaMiercolesNegocioCliente: 0,
                trabajaJuevesNegocioCliente: 0,
                trabajaViernesNegocioCliente: 0,
                trabajaSabadoNegocioCliente: 0,
                trabajaDomingoNegocioCliente: 0,
                trabajaLunesTrabajoCliente: 0,
                trabajaMartesTrabajoCliente: 0,
                trabajaMiercolesTrabajoCliente: 0,
                trabajaJuevesTrabajoCliente: 0,
                trabajaViernesTrabajoCliente: 0,
                trabajaSabadoTrabajoCliente: 0,
                trabajaDomingoTrabajoCliente: 0,
                numeroTelefonoNegocioCliente: "",
                tieneOtrosIngresosCliente: 0,
                descripcionOtrosIngresosClientes: "",
                viviendaDeclaracionPatrimonialCliente: 0,
                vehiculosDeclaracionPatrimonialCliente: 0,
                mobiliarioEquipoDeclaracionPatrimonial: 0,
                otrosDeclaracionPatrimonialCliente: 0,
                haTenidoCreditoBancariosCliente: 0,
                institucionDieronCreditoCliente: "",
                montoCreditoCliente: 0,
                nombreCompletoPersonaReferencia1Cliente: "",
                parentescoPersonaReferencia1Cliente: "",
                numeroTelefonoPersonaReferencia1Cliente: "",
                nombreCompletoPersonaReferencia2Cliente: "",
                parentescoPersonaReferencia2Cliente: "",
                numeroTelefonoPersonaReferencia2Cliente: "",
                calle_y_numeroCasaCliente: "",
                gastosRentaCasa: 0,
                gastosEducacionCasa: 0,
                gastosSaludCasa: 0,
                gastosAguaCasa: 0,
                gastosLuzCasa: 0,
                gastosInternetCasa: 0,
                gastosOtrosCasa: 0,
                horaEntradaTrabajoNegocio: "",
                horaSalidaTrabajoNegocio: "",
                imgFotoPersona: "",
                imgFachadaCasa: "",
                imgINE: "",
                IdReligion: 0,
                descripcionOtraReligion: "",
            },
            // cliente : {
            //     "IdCliente" : "",
            //     "nombreCliente" : "000 000",
            //     "apellidoPaternoCliente" : "000",
            //     "apellidoMaternoCliente" : "000",
            //     "correoElectronicoCliente" : "Alberttovalentine19@gmail.com",
            //     "IdPaisNacimientoCliente" : 1,
            //     "IdEstadoNacimientoCliente" : 7,
            //     "IdCiudadNacimientoCliente" : 7101,
            //     "IdEstadoCivilCliente" : 5,
            //     "claveElectorCliente" : "PRGMOS96032707H200",
            //     "INEFolioCliente" : "2521530497",
            //     "INENumeroCliente" : "097806909",
            //     "CURPCliente" : "PEGO960327HCSRMS04",
            //     "RFCCliente" : "PEGO960327kk4",
            //     "IdPaisViviendaCliente" : 1,
            //     "IdEstadoViviendaCliente" : 7,
            //     "IdCiudadViviendaClietne" : "7101",
            //     "IdLocalidadViviendaCliente" : "76184",
            //     "dependientesEconimicosCliente" : "-",
            //     "IdTipoViviendaCliente" : 2,
            //     "IdNivelEstudioCliente" : 4,
            //     "nombreConyugueCliente" : "-",
            //     "numeroTelefonoCliente" : "9613629345",
            //     "gastoRentaViviendaCliente" : 0,
            //     "gastoInternetViviendaCliente" : 0,
            //     "gastoLuzViviendaCliente" : 0,
            //     "gastoEducacionViviendaCliente" : 0,
            //     "gastosSaludViviendaCliente" : 0,
            //     "gastosOtrosViviendaCliente" : 0,
            //     "esTrabajadorCliente" : 1,
            //     "tieneNegocioCliente" : 0,
            //     "nombreLugarTrabajoCliente" : "",
            //     "puestoCategoriaTrabajoCliente" : "SUBGERENTE",
            //     "numeroTelefonoTrabajoCliente" : "",
            //     "direccionTrabajoCliente" : "OCALLE DIEGO DE MAZARIEGOS COLONIA CENTRO, SAN CRISTOBAL DE LAS CASAS",
            //     "puntpReferenciaTrabajoCliente" : "FRENTE AL PARQUE CENTRAL A LADO DE BANCOMER",
            //     "antiguedadTrabajoCliente" : "8 MESES",
            //     "ingresoMensualTrabajoCliente" : 12500,
            //     "nombreNegocioCliente" : "",
            //     "actividadServicioNegocioCliente" : "",
            //     "ventasSemanalesNegocioCliente" : 0,
            //     "inversionSemanalNegocioCliente" : 0,
            //     "IdTipoLocalNegocioCliente" : 0,
            //     "gastosRentaNegocioCliente" : 0,
            //     "gastosAguaNegocioCliente" : 0,
            //     "gastosLuzNegocioCliente" : 0,
            //     "gastosInternetNegocioCliente" : 0,
            //     "gastosOtrosNegocioCliente" : 0,
            //     "mobiliarioEquipoNegocioCliente" : 0,
            //     "cuantoTieneMercanciaCliente" : 0,
            //     "cuantoDineroDebenCliente" : 0,
            //     "cuantoTieneEfectivoAhorradoCliente" : 0,
            //     "cuantoTieneGananciaNegocio" : "0",
            //     "trabajaLunesNegocioCliente" : 0,
            //     "trabajaMartesNegocioCliente" : 0,
            //     "trabajaMiercolesNegocioCliente" : 1,
            //     "trabajaJuevesNegocioCliente" : 1,
            //     "trabajaViernesNegocioCliente" : 1,
            //     "trabajaSabadoNegocioCliente" : 1,
            //     "trabajaDomingoNegocioCliente" : 1,
            //     "trabajaLunesTrabajoCliente" : 0,
            //     "trabajaMartesTrabajoCliente" : 0,
            //     "trabajaMiercolesTrabajoCliente" : 0,
            //     "trabajaJuevesTrabajoCliente" : 0,
            //     "trabajaViernesTrabajoCliente" : 0,
            //     "trabajaSabadoTrabajoCliente" : 0,
            //     "trabajaDomingoTrabajoCliente" : 0,
            //     "numeroTelefonoNegocioCliente" : "9613629345",
            //     "tieneOtrosIngresosCliente" : 0,
            //     "descripcionOtrosIngresosClientes" : "",
            //     "viviendaDeclaracionPatrimonialCliente" : 0,
            //     "vehiculosDeclaracionPatrimonialCliente" : 0,
            //     "mobiliarioEquipoDeclaracionPatrimonial" : 0,
            //     "otrosDeclaracionPatrimonialCliente" : 0,
            //     "haTenidoCreditoBancariosCliente" : 0,
            //     "institucionDieronCreditoCliente" : "",
            //     "montoCreditoCliente" : 0,
            //     "nombreCompletoPersonaReferencia1Cliente" : "KETMIA FABIOLA NUCAMENDI BRISEÑO",
            //     "parentescoPersonaReferencia1Cliente" : "AMIGA",
            //     "numeroTelefonoPersonaReferencia1Cliente" : "9612473973",
            //     "nombreCompletoPersonaReferencia2Cliente" : "AMELIA BERMUDEZ HERNANDEZ",
            //     "parentescoPersonaReferencia2Cliente" : "AMIGA",
            //     "numeroTelefonoPersonaReferencia2Cliente" : "9671275690",
            //     "calle_y_numeroCasaCliente" : "CALLE ESMERALDA MZ 3 LT 35 ",
            //     "gastosRentaCasa" : 0,
            //     "gastosEducacionCasa" : 0,
            //     "gastosSaludCasa" : 0,
            //     "gastosAguaCasa" : 5250,
            //     "gastosLuzCasa" : 230,
            //     "gastosInternetCasa" : 739,
            //     "gastosOtrosCasa" : 1500,
            //     "descripcionLocalPropioRentado" : "0",
            //     "horaEntradaTrabajoNegocio" : "16:00:00",
            //     "horaSalidaTrabajoNegocio" : "04:00:00",
            //     "direccionReferencia1Cliente" : "CALLE SELENITA 136 JARDINES DEL PEDREGAL PRIMERA SECCION",
            //     "direccionReferencia2Cliente" : "CALLE JOSE NERVO NUMERO 7 COLONIA ONCE CUARTOS SAN CRISTOBAL DE LAS CASAS",
            //     "fechaNacimientoCliente" : "1996-03-27",
            //     "imgFotoPersona" : "",
            //     "imgFachadaCasa" : "",
            //     "imgINE" : "",
            //     "IdReligion" : 11,
            //     "descripcionOtraReligion" : null
            // },
            solicitudCredito: {
                IdCliente: 0,
                IdTipoSolicitudCredito: 0,
                IdMontotoSolicitudCredito: 0,
                IdPlazoPagoSolicitudCredito: 0,
                IdFormaPagoSolicitudCredito: 0,
            },
            style: {
                rows: {
                    up: {
                        marginTop: props.isDesktopDevice ? "100px" : "20px",
                        backgroundColor: colors.azul,
                        color: colors.blanco,
                        marginRight: props.isDesktopDevice ? "30px" : "5px",
                        marginLeft: props.isDesktopDevice ? "30px" : "5px",
                        borderRadius: "8px 8px 0px 0px",
                        // backgroundColor: "rgba(255,255,255,0.75)",
                    },
                    center: {
                        // marginTop: "100px",
                        backgroundColor: colors.blanco,
                        color: colors.azul,
                        marginRight: props.isDesktopDevice ? "30px" : "5px",
                        marginLeft: props.isDesktopDevice ? "30px" : "5px",
                        borderRadius: "0px 0px 0px 0px",
                        // backgroundColor: "rgba(255,255,255,0.90)",
                    },
                    down: {
                        //marginTop: "100px",
                        backgroundColor: colors.blanco,
                        color: colors.azul,
                        marginRight: props.isDesktopDevice ? "30px" : "5px",
                        marginLeft: props.isDesktopDevice ? "30px" : "5px",
                        borderRadius: "0px 0px 8px 8px",
                        marginBottom: "20px",
                        // backgroundColor: "rgba(255,255,255,0.75)",
                    }
                },
                button: {
                    backgroundColor: colors.verde_1,
                    boxShadow: "none",
                    borderColor: colors.azul,
                    color: colors.blanco,
                    fontWeight: "bold",
                    marginLeft: this.props.isDesktopDevice ? "15px" : "",
                    width: this.props.isDesktopDevice ? "" : "100%",
                    marginTop: this.props.isDesktopDevice ? "" : "15px"
                }
            },
            clienteAceptaPoliticas: !false,
            abrirModalAceptoPrivacidadCLiente: false,
            listaEstadosNacimiento: [],
            listaEstadosVivienda: [],
            listaCiudadesNacimiento: [],
            listaCiudadesVivienda: [],
            listaLocalidadVivienda: [],
            listaNivelEstudios: [],
            listaEstadosCiviles: [],
            listaTipoVivienda: [],
            listaFormasPago: [],
            listaMontoPago: [],
            listaPlazoPagoCredito: [],
            listaReligiones: [],
            mostrarBotonesEscogerTieneNegocioTrabajo: true,
            esTrabajadorTieneNegocio: 0,
        }

    }
    handleTakePhoto = () => {
        const image = this.camera.current.takePhoto();
        this.setState({ image });
    };
    camera = React.createRef();

    componentDidMount() {
        this.props.isDesktopDevice ?
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`
        this.cargarDatosPagina();
    }

    UUID = () => {
        return new Date().toLocaleString();
    }

    cargarDatosPagina = async () => {
        let response = await ClientServeci.getNivelEstudios();
        let { listaNivelEstudios } = response;
        response = await ClientServeci.getEstadosCiviles();
        let { listaEstadosCiviles } = response;
        response = await ClientServeci.getTipoVivienda();
        let { listaTipoVivienda } = response;
        response = await ClientServeci.getListaFormaPago();
        let { listaFormasPago } = response;
        response = await ClientServeci.getListaPlazoPagoCredito();
        let { listaPlazoPagoCredito } = response;
        response = await ClientServeci.getListaMontoCredito();
        let { listaMontoPago } = response;
        response = await ClientServeci.getListaReligiones();
        let { listaReligiones } = response;

        console.log(listaReligiones);

        await this.setState({
            listaNivelEstudios,
            listaEstadosCiviles,
            listaTipoVivienda,
            listaFormasPago,
            listaPlazoPagoCredito,
            listaMontoPago,
            listaReligiones
        });

    }

    cambiarDatosCliente = async (event) => {
        let { name, value } = event.target;

        await this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: value,
            }
        });
    }

    cambiarDatosSolicitudCredito = async (event) => {
        let { name, value } = event.target;

        await this.setState({
            solicitudCredito: {
                ...this.state.solicitudCredito,
                [name]: value,
            }
        });

    }


    guardarCliente = async () => {

        let { cliente } = await this.state;

        let value = await ClientServeci.validarDatosCliente(cliente, toast, this.state.mostrarBotonesEscogerTieneNegocioTrabajo, this.state.solicitudCredito);

        if (value) {
            toast.info("Creado tu solicitud de crédito");
            let response = await ClientServeci.guardarCliente(cliente);

            let { IdCliente } = response;

            await this.setState({
                cliente: {
                    ...this.state.cliente,
                    IdCliente,
                },
            });
            await this.guardarSolicitudCredito(IdCliente);
        }


    }

    guardarSolicitudCredito = async (IdCliente) => {

        await this.setState({
            solicitudCredito: {
                ...this.state.solicitudCredito,
                IdCliente
            }
        });

        await solicitudCreditoModel.guardarSolicitudCredito({
            IdCliente,
            IdTipoSolicitudCredito: this.state.solicitudCredito.IdTipoSolicitudCredito,
            IdMontotoSolicitudCredito: this.state.solicitudCredito.IdMontotoSolicitudCredito,
            IdPlazoPagoSolicitudCredito: this.state.solicitudCredito.IdPlazoPagoSolicitudCredito,
            IdFormaPagoSolicitudCredito: this.state.solicitudCredito.IdFormaPagoSolicitudCredito,
        });


    }


    nuevaSolicitud = () => {
        this.setState({
            clienteAceptaPoliticas: false,
            cliente: {
                IdCliente: 0,
                fechaNacimientoCliente: "",
                direccionReferencia1Cliente: "",
                direccionReferencia2Cliente: "",
                descripcionLocalPropioRentado: 0,
                nombreCliente: "",
                apellidoPaternoCliente: "",
                apellidoMaternoCliente: "",
                correoElectronicoCliente: "",
                IdPaisNacimientoCliente: 0,
                IdEstadoNacimientoCliente: 0,
                IdCiudadNacimientoCliente: 0,
                IdEstadoCivilCliente: 0,
                claveElectorCliente: "",
                INEFolioCliente: "",
                INENumeroCliente: "",
                CURPCliente: "",
                RFCCliente: "",
                IdPaisViviendaCliente: 0,
                IdEstadoViviendaCliente: 0,
                IdCiudadViviendaClietne: 0,
                IdLocalidadViviendaCliente: 0,
                dependientesEconimicosCliente: "",
                IdTipoViviendaCliente: 0,
                IdNivelEstudioCliente: 0,
                nombreConyugueCliente: "",
                numeroTelefonoCliente: "",
                gastoRentaViviendaCliente: 0,
                gastoInternetViviendaCliente: 0,
                gastoLuzViviendaCliente: 0,
                gastoEducacionViviendaCliente: 0,
                gastosSaludViviendaCliente: 0,
                gastosOtrosViviendaCliente: 0,
                esTrabajadorCliente: 0,
                tieneNegocioCliente: 0,
                nombreLugarTrabajoCliente: "",
                puestoCategoriaTrabajoCliente: "",
                numeroTelefonoTrabajoCliente: "",
                direccionTrabajoCliente: "",
                puntpReferenciaTrabajoCliente: "",
                antiguedadTrabajoCliente: "",
                ingresoMensualTrabajoCliente: 0,
                nombreNegocioCliente: "",
                actividadServicioNegocioCliente: "",
                ventasSemanalesNegocioCliente: 0,
                inversionSemanalNegocioCliente: 0,
                IdTipoLocalNegocioCliente: 0,
                gastosRentaNegocioCliente: 0,
                gastosAguaNegocioCliente: 0,
                gastosLuzNegocioCliente: 0,
                gastosInternetNegocioCliente: 0,
                gastosOtrosNegocioCliente: 0,
                mobiliarioEquipoNegocioCliente: 0,
                cuantoTieneMercanciaCliente: 0,
                cuantoDineroDebenCliente: 0,
                cuantoTieneEfectivoAhorradoCliente: 0,
                cuantoTieneGananciaNegocio: 0,
                trabajaLunesNegocioCliente: 0,
                trabajaMartesNegocioCliente: 0,
                trabajaMiercolesNegocioCliente: 0,
                trabajaJuevesNegocioCliente: 0,
                trabajaViernesNegocioCliente: 0,
                trabajaSabadoNegocioCliente: 0,
                trabajaDomingoNegocioCliente: 0,
                trabajaLunesTrabajoCliente: 0,
                trabajaMartesTrabajoCliente: 0,
                trabajaMiercolesTrabajoCliente: 0,
                trabajaJuevesTrabajoCliente: 0,
                trabajaViernesTrabajoCliente: 0,
                trabajaSabadoTrabajoCliente: 0,
                trabajaDomingoTrabajoCliente: 0,
                numeroTelefonoNegocioCliente: "",
                tieneOtrosIngresosCliente: 0,
                descripcionOtrosIngresosClientes: "",
                viviendaDeclaracionPatrimonialCliente: 0,
                vehiculosDeclaracionPatrimonialCliente: 0,
                mobiliarioEquipoDeclaracionPatrimonial: 0,
                otrosDeclaracionPatrimonialCliente: 0,
                haTenidoCreditoBancariosCliente: 0,
                institucionDieronCreditoCliente: "",
                montoCreditoCliente: 0,
                nombreCompletoPersonaReferencia1Cliente: "",
                parentescoPersonaReferencia1Cliente: "",
                numeroTelefonoPersonaReferencia1Cliente: "",
                nombreCompletoPersonaReferencia2Cliente: "",
                parentescoPersonaReferencia2Cliente: "",
                numeroTelefonoPersonaReferencia2Cliente: "",
                calle_y_numeroCasaCliente: "",
                gastosRentaCasa: 0,
                gastosEducacionCasa: 0,
                gastosSaludCasa: 0,
                gastosAguaCasa: 0,
                gastosLuzCasa: 0,
                gastosInternetCasa: 0,
                gastosOtrosCasa: 0,
                horaEntradaTrabajoNegocio: "",
                horaSalidaTrabajoNegocio: "",
                imgFotoPersona: "",
                imgFachadaCasa: "",
                imgINE: "",
                IdReligion: 0,
                descripcionOtraReligion: "",
            },
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />

                <Row
                    style={this.state.style.rows.up}
                >
                    <Col>
                        <center>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: this.props.isDesktopDevice ? "30px" : "28px",
                                    marginTop: "20px",
                                    marginBottom: "20px",

                                }}
                            >
                                {this.props.isDesktopDevice ? "TZEDAKÁ SOLICITUD DE CRÉDITO" : "SOLICITUD DE CRÉDITO"}
                            </Label>
                        </center>
                    </Col>
                </Row>


                {/* Datos Generales */}
                <Row
                    style={this.state.style.rows.center}
                >
                    <Col>
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                        >
                            Datos Generales
                        </Label>
                    </Col>
                </Row>


                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Nombre(s)
                            </Label>
                            <Input
                                id="nombreCliente"
                                name="nombreCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.nombreCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Apellido Paterno
                            </Label>
                            <Input
                                id="apellidoPaternoCliente"
                                name="apellidoPaternoCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.apellidoPaternoCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Apellido Materno
                            </Label>
                            <Input
                                id="apellidoMaternoCliente"
                                name="apellidoMaternoCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.apellidoMaternoCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Numero Telefono
                            </Label>
                            <Input
                                id="numeroTelefonoCliente"
                                name="numeroTelefonoCliente"
                                placeholder="Ejemplo: 9610001122"
                                type="text"
                                value={this.state.cliente.numeroTelefonoCliente}
                                onChange={(event) => {
                                    let { value } = event.target;
                                    if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                        this.cambiarDatosCliente(event);
                                    }
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Correo Electrónico
                            </Label>
                            <Input
                                id="correoElectronicoCliente"
                                name="correoElectronicoCliente"
                                placeholder="Ejemplo: cuentCliente@mail.com"
                                type="text"
                                value={this.state.cliente.correoElectronicoCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                País de Nacimiento
                            </Label>
                            <Input
                                id="IdPaisNacimientoCliente"
                                name="IdPaisNacimientoCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdPaisNacimientoCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                    let response = await localidadModel.getEstadosPorPais(event.target.value)
                                    let { listaEstados } = response;

                                    if (event.target.value == 0) {
                                        await this.setState({
                                            cliente: {
                                                ...this.state.cliente,
                                                IdCiudadNacimientoCliente: 0,
                                                IdEstadoNacimientoCliente: 0,
                                            }
                                        });
                                    } else {
                                        await this.setState({
                                            listaEstadosNacimiento: listaEstados,
                                        });
                                    }
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                <option value={1}>México</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Estado de Nacimiento
                            </Label>
                            <Input
                                id="IdEstadoNacimientoCliente"
                                name="IdEstadoNacimientoCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdEstadoNacimientoCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                    let response = await localidadModel.getCiudadesPorEstados(event.target.value);
                                    let { listaCiudades } = response;

                                    await this.setState({
                                        listaCiudadesNacimiento: listaCiudades
                                    });
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaEstadosNacimiento.map((estado) => {
                                        return (
                                            <option value={estado.IdEstado}>{estado.nombreEstado}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Ciudad de Nacimiento
                            </Label>
                            <Input
                                id="IdCiudadNacimientoCliente"
                                name="IdCiudadNacimientoCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdCiudadNacimientoCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaCiudadesNacimiento.map((ciudad) => {
                                        return (
                                            <option value={ciudad.IdCiudad}>{ciudad.nombreCiudad}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Nivel de Estudio
                            </Label>
                            <Input
                                id="IdNivelEstudioCliente"
                                name="IdNivelEstudioCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdNivelEstudioCliente}
                                onChange={(event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaNivelEstudios.map((nivelEstudio) => {
                                        return (
                                            <option value={nivelEstudio.IdEstudio}>{nivelEstudio.descripcion}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Estado Civil
                            </Label>
                            <Input
                                id="IdEstadoCivilCliente"
                                name="IdEstadoCivilCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdEstadoCivilCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaEstadosCiviles.map((estadoCivil) => {
                                        return (
                                            <option value={estadoCivil.IdEstadoCivil}>{estadoCivil.nombreEstadoCivil}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>

                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Clave de Elector
                            </Label>
                            <Input
                                id="claveElectorCliente"
                                name="claveElectorCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.claveElectorCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                INE Folio
                            </Label>
                            <Input
                                id="INEFolioCliente"
                                name="INEFolioCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.INEFolioCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                INE Numero
                            </Label>
                            <Input
                                id="INENumeroCliente"
                                name="INENumeroCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.INENumeroCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                CURP
                            </Label>
                            <Input
                                id="CURPCliente"
                                name="CURPCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.CURPCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                RFC
                            </Label>
                            <Input
                                id="RFCCliente"
                                name="RFCCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.RFCCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                País Donde Vives
                            </Label>
                            <Input
                                id="IdPaisViviendaCliente"
                                name="IdPaisViviendaCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdPaisViviendaCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                    let response = await localidadModel.getEstadosPorPais(event.target.value)
                                    let { listaEstados } = response;

                                    if (event.target.value == 0) {
                                        await this.setState({
                                            cliente: {
                                                ...this.state.cliente,
                                                IdCiudadViviendaCliente: 0,
                                                IdEstadoViviendaCliente: 0,
                                            },
                                            listaEstadosVivienda: [],
                                            listaCiudadesVivienda: [],
                                            listaLocalidadVivienda: []
                                        });
                                    } else {
                                        await this.setState({
                                            listaEstadosVivienda: listaEstados,
                                            listaCiudadesVivienda: []
                                        });
                                    }
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                <option value={1}>México</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Estado Donde Vives
                            </Label>
                            <Input
                                id="IdEstadoViviendaCliente"
                                name="IdEstadoViviendaCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdEstadoViviendaCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                    let response = await localidadModel.getCiudadesPorEstados(event.target.value);
                                    let { listaCiudades } = response;

                                    await this.setState({
                                        listaCiudadesVivienda: listaCiudades
                                    });
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaEstadosVivienda.map((estado) => {
                                        return (
                                            <option value={estado.IdEstado}>{estado.nombreEstado}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Ciudad Donde Vives
                            </Label>
                            <Input
                                id="IdCiudadViviendaClietne"
                                name="IdCiudadViviendaClietne"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdCiudadViviendaClietne}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                    let response = await localidadModel.getLocaldiadPorCiudad(event.target.value);

                                    let { listaLocalidad } = response;
                                    await this.setState({
                                        listaLocalidadVivienda: listaLocalidad
                                    });

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaCiudadesVivienda.map((ciudad) => {
                                        return (
                                            <option value={ciudad.IdCiudad}>{ciudad.nombreCiudad}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Localidad Donde Vives
                            </Label>
                            <Input
                                id="IdLocalidadViviendaCliente"
                                name="IdLocalidadViviendaCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdLocalidadViviendaCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaLocalidadVivienda.map((localidad) => {
                                        return (
                                            <option value={localidad.IdLocalidad}>{localidad.nombreLocalidad}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold"
                                }}
                            >
                                Calle y Numero de Casa
                            </Label>
                            <Input
                                id="calle_y_numeroCasaCliente"
                                name="calle_y_numeroCasaCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.calle_y_numeroCasaCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Tipo de Vivienda
                            </Label>
                            <Input
                                id="IdTipoViviendaCliente"
                                name="IdTipoViviendaCliente"
                                placeholder=""
                                type="select"
                                value={this.state.cliente.IdTipoViviendaCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaTipoVivienda.map((tipoVivienda) => {
                                        return (
                                            <option value={tipoVivienda.IdTipoVivienda}>{tipoVivienda.descripcionTipoVivienda}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Nombre del Cónyuge
                            </Label>
                            <Input
                                id="nombreConyugueCliente"
                                name="nombreConyugueCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.nombreConyugueCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Dependientes Económicos
                            </Label>
                            <Input
                                style={{
                                    // width : "900px",
                                }}
                                id="dependientesEconimicosCliente"
                                name="dependientesEconimicosCliente"
                                placeholder=""
                                type="text"
                                value={this.state.cliente.dependientesEconimicosCliente}
                                onChange={async (event) => {
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>

                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Renta
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosRentaCasa"
                                    name="gastosRentaCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosRentaCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Educación
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosEducacionCasa"
                                    name="gastosEducacionCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosEducacionCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Salud
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosSaludCasa"
                                    name="gastosSaludCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosSaludCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Agua
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosAguaCasa"
                                    name="gastosAguaCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosAguaCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Luz
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosLuzCasa"
                                    name="gastosLuzCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosLuzCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row
                    style={this.state.style.rows.center}
                >

                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Gastos de Internet
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosInternetCasa"
                                    name="gastosInternetCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosInternetCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Otros Gastos
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    $
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosOtrosCasa"
                                    name="gastosOtrosCasa"
                                    placeholder="0.00"
                                    type="number"
                                    value={this.state.cliente.gastosOtrosCasa}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            this.cambiarDatosCliente(event);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Fecha de Nacimiento
                            </Label>
                            <InputGroup>
                                <InputGroupText>
                                    <CalendarToday />
                                </InputGroupText>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="fechaNacimientoCliente"
                                    name="fechaNacimientoCliente"
                                    placeholder="0.00"
                                    type="date"
                                    value={this.state.cliente.fechaNacimientoCliente}
                                    onChange={async (event) => {
                                        this.cambiarDatosCliente(event);
                                    }}
                                    onClick={() => {
                                        if (!this.state.clienteAceptaPoliticas) {
                                            this.setState({
                                                abrirModalAceptoPrivacidadCLiente: true,
                                            });
                                        }
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Religion que Profesa
                            </Label>
                            <Input
                                style={{
                                    // width : "900px",
                                }}
                                id="IdReligion"
                                name="IdReligion"
                                type="select"
                                value={this.state.cliente.IdReligion}
                                onChange={async (event) => {
                                    if(event.target.value == "12"){
                                        components.sleep(0.3).then(()=>{
                                            components.fucusElement("descripcionOtraReligion");
                                        });                                    
                                    }
                                    this.cambiarDatosCliente(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaReligiones.map((religion) => {
                                        return (
                                            <option value={religion.IdReligion}>{religion.religion}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    {
                        this.state.cliente.IdReligion == "12" ?
                            <Col
                                md={this.props.isDesktopDevice ? "" : "2"}
                                style={{
                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Especifica que religion profesas
                                    </Label>
                                    <Input
                                        id="descripcionOtraReligion"
                                        name="descripcionOtraReligion"
                                        placeholder=""
                                        type="text"
                                        value={this.state.cliente.descripcionOtraReligion}
                                        onChange={async (event) => {
                                            if (event.target.value.length > 100) return;
                                            this.cambiarDatosCliente(event);
                                        }}
                                        onClick={() => {
                                            if (!this.state.clienteAceptaPoliticas) {
                                                this.setState({
                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                });
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            :
                            null
                    }

                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <DropdownItem divider />

                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col>
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                        >
                            {this.state.referenciaTrabajoSucursal[this.state.i]}
                        </Label>
                        {/* {
                            this.state.mostrarBotonesEscogerTieneNegocioTrabajo ?
                                null
                                :
                                <IconButton
                                    onClick={async () => {
                                        await this.setState({
                                            mostrarBotonesEscogerTieneNegocioTrabajo: true,
                                            esTrabajadorTieneNegocio: 0,
                                            esTrabajadorCliente: 0
                                        });
                                    }}
                                    title="Mostrar opciones de Empleo/Negocio"
                                >
                                    <Cached
                                        style={{
                                            color: colors.azul
                                        }}
                                    />
                                </IconButton>
                        } */}
                    </Col>
                </Row>
                {
                    this.state.mostrarBotonesEscogerTieneNegocioTrabajo ?
                        <Row
                            style={this.state.style.rows.center}
                        >
                            <Col>
                                <Button
                                    onClick={async () => {
                                        await this.setState({
                                            mostrarBotonesEscogerTieneNegocioTrabajo: false,
                                            i: 1,

                                            cliente: {
                                                ...this.state.cliente,
                                                esTrabajadorCliente: 1,
                                                tieneNegocioCliente: 0
                                            }
                                        });
                                    }}
                                    style={{
                                        backgroundColor: colors.verde_1,
                                        borderColor: colors.verde_1,
                                        boxShadow: "none",
                                        width: this.props.isDesktopDevice ? "200px" : "100%",
                                        marginRight: "15px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Soy Trabajador
                                </Button>
                                {
                                    this.props.isDesktopDevice ?
                                        null
                                        :
                                        <>
                                            <br />
                                            <br />
                                        </>
                                }

                                <Button
                                    onClick={async () => {
                                        await this.setState({
                                            mostrarBotonesEscogerTieneNegocioTrabajo: false,
                                            i: 2,
                                            cliente: {
                                                ...this.state.cliente,
                                                esTrabajadorCliente: 0,
                                                tieneNegocioCliente: 1
                                            }

                                        });
                                    }}
                                    style={{
                                        backgroundColor: colors.verde_1,
                                        borderColor: colors.verde_1,
                                        boxShadow: "none",
                                        width: this.props.isDesktopDevice ? "200px" : "100%",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Tengo un Negocio
                                </Button>
                            </Col>
                        </Row>
                        :
                        null
                }

                {
                    !this.state.mostrarBotonesEscogerTieneNegocioTrabajo ?
                        <>
                            <Row
                                style={this.state.style.rows.center}
                            >
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Puesto/Categoría
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="puestoCategoriaTrabajoCliente"
                                            name="puestoCategoriaTrabajoCliente"
                                            placeholder=""
                                            type="text"
                                            value={this.state.cliente.puestoCategoriaTrabajoCliente}
                                            onChange={async (event) => {
                                                this.cambiarDatosCliente(event);
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Telefono
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="numeroTelefonoNegocioCliente"
                                            name="numeroTelefonoNegocioCliente"
                                            placeholder=""
                                            type="text"
                                            value={this.state.cliente.numeroTelefonoNegocioCliente}
                                            onChange={async (event) => {
                                                let { value } = event.target;
                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                    this.cambiarDatosCliente(event);
                                                }
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Dirección
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="direccionTrabajoCliente"
                                            name="direccionTrabajoCliente"
                                            placeholder=""
                                            type="text"
                                            value={this.state.cliente.direccionTrabajoCliente}
                                            onChange={async (event) => {
                                                this.cambiarDatosCliente(event);
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Punto de Referencia
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="puntpReferenciaTrabajoCliente"
                                            name="puntpReferenciaTrabajoCliente"
                                            placeholder=""
                                            type="text"
                                            value={this.state.cliente.puntpReferenciaTrabajoCliente}
                                            onChange={async (event) => {
                                                this.cambiarDatosCliente(event);
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {this.state.esTrabajadorCliente === 1 ? "Tiempo con tu Negocio" : "Antigüedad en tu Trabajo"}
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="antiguedadTrabajoCliente"
                                            name="antiguedadTrabajoCliente"
                                            placeholder=""
                                            type="text"
                                            value={this.state.cliente.antiguedadTrabajoCliente}
                                            onChange={async (event) => {
                                                this.cambiarDatosCliente(event);
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row
                                style={this.state.style.rows.center}
                            >
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                    style={{
                                        maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Ingreso Mensual
                                        </Label>
                                        <InputGroup>
                                            <InputGroupText>
                                                $
                                            </InputGroupText>
                                            <Input
                                                style={{
                                                    // width : "900px",
                                                }}
                                                id="ingresoMensualTrabajoCliente"
                                                name="ingresoMensualTrabajoCliente"
                                                placeholder=""
                                                type="text"
                                                value={this.state.cliente.ingresoMensualTrabajoCliente}
                                                onChange={async (event) => {
                                                    this.cambiarDatosCliente(event);
                                                }}
                                                onClick={() => {
                                                    if (!this.state.clienteAceptaPoliticas) {
                                                        this.setState({
                                                            abrirModalAceptoPrivacidadCLiente: true,
                                                        });
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                    style={{
                                        maxWidth: this.props.isDesktopDevice ? "40%" : ""
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Que Dias Trabajas
                                        </Label>
                                        <br />
                                        <Input
                                            checked={this.state.cliente.trabajaLunesNegocioCliente === 1 ? true : false}
                                            style={{
                                                // width : "900px",
                                                marginRight: "5px"
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {
                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaLunesNegocioCliente: this.state.cliente.trabajaLunesNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                        >
                                            Lunes
                                        </Label>
                                        <Input
                                            checked={this.state.cliente.trabajaMartesNegocioCliente === 1 ? true : false}
                                            style={{
                                                // width : "900px",
                                                marginRight: "5px",
                                                marginLeft: "7px"
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            // value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {
                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaMartesNegocioCliente: this.state.cliente.trabajaMartesNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });

                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                        >
                                            Martes
                                        </Label>
                                        <Input
                                            checked={this.state.cliente.trabajaMiercolesNegocioCliente === 1 ? true : false}
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "6px"
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            // value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {
                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaMiercolesNegocioCliente: this.state.cliente.trabajaMiercolesNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });
                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    if (!this.state.clienteAceptaPoliticas) {
                                                        this.setState({
                                                            abrirModalAceptoPrivacidadCLiente: true,
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                        >
                                            Miércoles
                                        </Label>
                                        <br />
                                        <Input
                                            checked={this.state.cliente.trabajaJuevesNegocioCliente === 1 ? true : false}
                                            style={{
                                                marginRight: "5px"
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {

                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaJuevesNegocioCliente: this.state.cliente.trabajaJuevesNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });

                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            Jueves
                                        </Label>
                                        <Input
                                            checked={this.state.cliente.trabajaViernesNegocioCliente === 1 ? true : false}
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {


                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaViernesNegocioCliente: this.state.cliente.trabajaViernesNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });

                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                        >
                                            Viernes
                                        </Label>
                                        <Input
                                            checked={this.state.cliente.trabajaSabadoNegocioCliente === 1 ? true : false}

                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {


                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaSabadoNegocioCliente: this.state.cliente.trabajaSabadoNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });

                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px",
                                                marginRight: "5px"
                                            }}
                                        >
                                            Sábado
                                        </Label>
                                        <br />
                                        <Input
                                            checked={this.state.cliente.trabajaDomingoNegocioCliente === 1 ? true : false}
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder=""
                                            type="checkbox"
                                            value={this.state.cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {

                                                this.setState({
                                                    cliente: {
                                                        ...this.state.cliente,
                                                        trabajaDomingoNegocioCliente: this.state.cliente.trabajaDomingoNegocioCliente === 1 ? 0 : 1
                                                    }
                                                });

                                            }}
                                            onClick={() => {
                                                if (!this.state.clienteAceptaPoliticas) {
                                                    this.setState({
                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                    });
                                                }
                                            }}
                                        />
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            Domingo
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                    style={{
                                        maxWidth: this.props.isDesktopDevice ? "40%" : ""
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Horario de Trabajo (Entrada Salida)
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                style={{
                                                    marginRight: "2.5px"
                                                }}
                                                id="horaEntradaTrabajoNegocio"
                                                name="horaEntradaTrabajoNegocio"
                                                placeholder=""
                                                type="time"
                                                value={this.state.cliente.horaEntradaTrabajoNegocio}
                                                onChange={async (event) => {
                                                    this.cambiarDatosCliente(event);
                                                }}
                                                onClick={() => {
                                                    if (!this.state.clienteAceptaPoliticas) {
                                                        this.setState({
                                                            abrirModalAceptoPrivacidadCLiente: true,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Input
                                                style={{
                                                    marginLeft: "2.5px"
                                                }}
                                                id="horaSalidaTrabajoNegocio"
                                                name="horaSalidaTrabajoNegocio"
                                                placeholder=""
                                                type="time"
                                                value={this.state.cliente.horaSalidaTrabajoNegocio}
                                                onChange={async (event) => {
                                                    this.cambiarDatosCliente(event);
                                                }}
                                                onClick={() => {
                                                    if (!this.state.clienteAceptaPoliticas) {
                                                        this.setState({
                                                            abrirModalAceptoPrivacidadCLiente: true,
                                                        });
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                this.state.cliente.tieneNegocioCliente === 1 ?
                                    <>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >
                                            <DropdownItem divider />
                                            <Col>
                                                <Label
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "24px",
                                                        marginTop: "20px",
                                                        marginBottom: "20px"
                                                    }}
                                                >
                                                    Análisis del Negocio
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >

                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Ventas Semanales
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="ventasSemanalesNegocioCliente"
                                                            name="ventasSemanalesNegocioCliente"
                                                            placeholder=""
                                                            type="text"
                                                            value={this.state.cliente.ventasSemanalesNegocioCliente}
                                                            onChange={async (event) => {
                                                                this.cambiarDatosCliente(event);
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Inversion Semanal
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="inversionSemanalNegocioCliente"
                                                            name="inversionSemanalNegocioCliente"
                                                            placeholder=""
                                                            type="text"
                                                            value={this.state.cliente.inversionSemanalNegocioCliente}
                                                            onChange={async (event) => {
                                                                this.cambiarDatosCliente(event);
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ¿Tu Local Es?
                                                    </Label>
                                                    <Input
                                                        id="descripcionLocalPropioRentado"
                                                        name="descripcionLocalPropioRentado"
                                                        placeholder=""
                                                        type="select"
                                                        value={this.state.cliente.descripcionLocalPropioRentado}
                                                        onChange={async (event) => {
                                                            this.cambiarDatosCliente(event);
                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <option value={""}>Escoge una opción</option>
                                                        <option value={"Rentado"}>Rentado</option>
                                                        <option value={"Proprio"}>Propio</option>
                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Gastos de Renta de tu Negocio
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="gastosRentaNegocioCliente"
                                                            name="gastosRentaNegocioCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.gastosRentaNegocioCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Gastos de Agua de tu Negocio
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="gastosAguaNegocioCliente"
                                                            name="gastosAguaNegocioCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.gastosAguaNegocioCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Gastos de Luz de tu Negocio
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="gastosLuzNegocioCliente"
                                                            name="gastosLuzNegocioCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.gastosLuzNegocioCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Gastos de Internet de tu Negocio
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="gastosInternetNegocioCliente"
                                                            name="gastosInternetNegocioCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.gastosInternetNegocioCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Otros Gastos de tu Negocio
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="gastosOtrosNegocioCliente"
                                                            name="gastosOtrosNegocioCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.gastosOtrosNegocioCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Cuanto Tiene Mercancía
                                                    </Label>
                                                    <Input
                                                        style={{
                                                            // width : "900px",
                                                        }}
                                                        id="cuantoTieneMercanciaCliente"
                                                        name="cuantoTieneMercanciaCliente"
                                                        placeholder="0.00"
                                                        type="number"
                                                        value={this.state.cliente.cuantoTieneMercanciaCliente}
                                                        onChange={async (event) => {
                                                            let { value } = event.target;
                                                            if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                this.cambiarDatosCliente(event);
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ¿Cuanto Dinero le Deben?
                                                    </Label>
                                                    <Input
                                                        style={{
                                                            // width : "900px",
                                                        }}
                                                        id="cuantoDineroDebenCliente"
                                                        name="cuantoDineroDebenCliente"
                                                        placeholder="0.00"
                                                        type="number"
                                                        value={this.state.cliente.cuantoDineroDebenCliente}
                                                        onChange={async (event) => {
                                                            let { value } = event.target;
                                                            if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                this.cambiarDatosCliente(event);
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ¿Cuánto Tiene Efectivo/Ahorro?
                                                    </Label>
                                                    <Input
                                                        style={{
                                                            // width : "900px",
                                                        }}
                                                        id="cuantoTieneEfectivoAhorradoCliente"
                                                        name="cuantoTieneEfectivoAhorradoCliente"
                                                        placeholder="0.00"
                                                        type="number"
                                                        value={this.state.cliente.cuantoTieneEfectivoAhorradoCliente}
                                                        onChange={async (event) => {
                                                            let { value } = event.target;
                                                            if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                this.cambiarDatosCliente(event);
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "40%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ¿Cuánto Calcula que Tiene de Ganancia en su Negocio?
                                                    </Label>
                                                    <Input
                                                        style={{
                                                            // width : "900px",
                                                        }}
                                                        id="cuantoTieneGananciaNegocio"
                                                        name="cuantoTieneGananciaNegocio"
                                                        placeholder=""
                                                        type="text"
                                                        value={this.state.cliente.cuantoTieneGananciaNegocio}
                                                        onChange={async (event) => {

                                                            this.cambiarDatosCliente(event);

                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ¿Tienen Otros Ingresos?
                                                    </Label>
                                                    <FormGroup>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="tieneOtrosIngresosCliente"
                                                            name="tieneOtrosIngresosCliente"
                                                            placeholder=""
                                                            type="checkbox"
                                                            checked={this.state.cliente.tieneOtrosIngresosCliente === 1 ? true : false}
                                                            onChange={async (event) => {

                                                                await this.setState({
                                                                    cliente: {
                                                                        ...this.state.cliente,
                                                                        tieneOtrosIngresosCliente: this.state.cliente.tieneOtrosIngresosCliente === 1 ? 0 : 1
                                                                    }
                                                                });

                                                                if (await this.state.cliente.tieneOtrosIngresosCliente === 1) {
                                                                    components.fucusElement("descripcionOtrosIngresosClientes");
                                                                }

                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                marginLeft: "5px"
                                                            }}
                                                        >
                                                            Si
                                                        </Label>
                                                        <Input
                                                            style={{
                                                                marginLeft: "15px"
                                                            }}
                                                            id="tieneOtrosIngresosCliente"
                                                            name="tieneOtrosIngresosCliente"
                                                            placeholder=""
                                                            type="checkbox"
                                                            checked={this.state.cliente.tieneOtrosIngresosCliente === 1 ? false : true}
                                                            onChange={async (event) => {

                                                                this.setState({
                                                                    cliente: {
                                                                        ...this.state.cliente,
                                                                        tieneOtrosIngresosCliente: this.state.cliente.tieneOtrosIngresosCliente === 0 ? 1 : 0
                                                                    }
                                                                });

                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                marginLeft: "5px"
                                                            }}
                                                        >
                                                            No
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Describa Sus Otros Ingresos
                                                    </Label>

                                                    <Input
                                                        style={{
                                                            // width : "900px",
                                                        }}
                                                        id="descripcionOtrosIngresosClientes"
                                                        name="descripcionOtrosIngresosClientes"
                                                        placeholder=""
                                                        type="text"
                                                        value={this.state.cliente.descripcionOtrosIngresosClientes}
                                                        disabled={this.state.cliente.tieneOtrosIngresosCliente === 1 ? false : true}
                                                        onChange={async (event) => {

                                                            this.cambiarDatosCliente(event)

                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Declaración Patrimonial Vivienda
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="viviendaDeclaracionPatrimonialCliente"
                                                            name="viviendaDeclaracionPatrimonialCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.viviendaDeclaracionPatrimonialCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Declaracion Patrimonial Vehículos
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="vehiculosDeclaracionPatrimonialCliente"
                                                            name="vehiculosDeclaracionPatrimonialCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.vehiculosDeclaracionPatrimonialCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Declaracion Patrimonial Equipo
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="mobiliarioEquipoDeclaracionPatrimonial"
                                                            name="mobiliarioEquipoDeclaracionPatrimonial"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.mobiliarioEquipoDeclaracionPatrimonial}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Declaracion Patrimonial Otros
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="otrosDeclaracionPatrimonialCliente"
                                                            name="otrosDeclaracionPatrimonialCliente"
                                                            placeholder="0.00"
                                                            type="number"
                                                            value={this.state.cliente.otrosDeclaracionPatrimonialCliente}
                                                            onChange={async (event) => {
                                                                let { value } = event.target;
                                                                if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                                    this.cambiarDatosCliente(event);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Ha Tenido Otros Creditos
                                                    </Label>
                                                    <FormGroup>
                                                        <Input
                                                            style={{
                                                                // width : "900px",
                                                            }}
                                                            id="haTenidoCreditoBancariosCliente"
                                                            name="haTenidoCreditoBancariosCliente"
                                                            placeholder=""
                                                            type="checkbox"
                                                            checked={this.state.cliente.haTenidoCreditoBancariosCliente === 1 ? true : false}
                                                            onChange={async (event) => {

                                                                await this.setState({
                                                                    cliente: {
                                                                        ...this.state.cliente,
                                                                        haTenidoCreditoBancariosCliente: this.state.cliente.haTenidoCreditoBancariosCliente === 1 ? 0 : 1
                                                                    }
                                                                });

                                                                if (await this.state.cliente.haTenidoCreditoBancariosCliente === 1) {
                                                                    components.fucusElement("institucionDieronCreditoCliente");
                                                                }

                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                marginLeft: "5px"
                                                            }}
                                                        >
                                                            Si
                                                        </Label>
                                                        <Input
                                                            style={{
                                                                marginLeft: "15px"
                                                            }}
                                                            id="haTenidoCreditoBancariosCliente"
                                                            name="haTenidoCreditoBancariosCliente"
                                                            placeholder=""
                                                            type="checkbox"
                                                            checked={this.state.cliente.haTenidoCreditoBancariosCliente === 1 ? false : true}
                                                            onChange={async (event) => {

                                                                this.setState({
                                                                    cliente: {
                                                                        ...this.state.cliente,
                                                                        haTenidoCreditoBancariosCliente: this.state.cliente.haTenidoCreditoBancariosCliente === 0 ? 1 : 0
                                                                    }
                                                                });

                                                            }}
                                                            onClick={() => {
                                                                if (!this.state.clienteAceptaPoliticas) {
                                                                    this.setState({
                                                                        abrirModalAceptoPrivacidadCLiente: true,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                marginLeft: "5px"
                                                            }}
                                                        >
                                                            No
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={this.state.style.rows.center}
                                        >
                                            <Col
                                                md={this.props.isDesktopDevice ? "" : "2"}
                                                style={{
                                                    maxWidth: this.props.isDesktopDevice ? "20%" : ""
                                                }}
                                            >
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Institución Donde le Dieron el Crédito
                                                    </Label>
                                                    <Input
                                                        name="institucionDieronCreditoCliente"
                                                        id="institucionDieronCreditoCliente"
                                                        value={this.state.cliente.institucionDieronCreditoCliente}
                                                        disabled={this.state.cliente.haTenidoCreditoBancariosCliente === 1 ? false : true}
                                                        type="text"
                                                        onChange={async (event) => {
                                                            this.cambiarDatosCliente(event)

                                                        }}
                                                        onClick={() => {
                                                            if (!this.state.clienteAceptaPoliticas) {
                                                                this.setState({
                                                                    abrirModalAceptoPrivacidadCLiente: true,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    null
                            }

                        </>
                        :
                        null
                }

                <Row
                    style={this.state.style.rows.center}
                >
                    <DropdownItem divider />
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                        >
                            Referencias De Personas Que No Vivan Con Usted (2 Personas)
                        </Label>
                    </Col>
                </Row>

                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Nombre Completo y Apellido
                            </Label>
                            <Input
                                name="nombreCompletoPersonaReferencia1Cliente"
                                id="nombreCompletoPersonaReferencia1Cliente"
                                value={this.state.cliente.nombreCompletoPersonaReferencia1Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Parentesco
                            </Label>
                            <Input
                                name="parentescoPersonaReferencia1Cliente"
                                id="parentescoPersonaReferencia1Cliente"
                                value={this.state.cliente.parentescoPersonaReferencia1Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "40%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Dirección
                            </Label>
                            <Input
                                name="direccionReferencia1Cliente"
                                id="direccionReferencia1Cliente"
                                value={this.state.cliente.direccionReferencia1Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Teléfono
                            </Label>
                            <Input
                                name="numeroTelefonoPersonaReferencia1Cliente"
                                id="numeroTelefonoPersonaReferencia1Cliente"
                                value={this.state.cliente.numeroTelefonoPersonaReferencia1Cliente}
                                type="text"
                                onChange={async (event) => {
                                    let { value } = event.target;
                                    if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                        this.cambiarDatosCliente(event);
                                    }

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>


                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Nombre Completo y Apellido
                            </Label>
                            <Input
                                name="nombreCompletoPersonaReferencia2Cliente"
                                id="nombreCompletoPersonaReferencia2Cliente"
                                value={this.state.cliente.nombreCompletoPersonaReferencia2Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Parentesco
                            </Label>
                            <Input
                                name="parentescoPersonaReferencia2Cliente"
                                id="parentescoPersonaReferencia2Cliente"
                                value={this.state.cliente.parentescoPersonaReferencia2Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "40%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Direccion
                            </Label>
                            <Input
                                name="direccionReferencia2Cliente"
                                id="direccionReferencia2Cliente"
                                value={this.state.cliente.direccionReferencia2Cliente}
                                type="text"
                                onChange={async (event) => {

                                    this.cambiarDatosCliente(event);

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Telefono
                            </Label>
                            <Input
                                name="numeroTelefonoPersonaReferencia2Cliente"
                                id="numeroTelefonoPersonaReferencia2Cliente"
                                value={this.state.cliente.numeroTelefonoPersonaReferencia2Cliente}
                                type="text"
                                onChange={async (event) => {
                                    let { value } = event.target;
                                    if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                        this.cambiarDatosCliente(event);
                                    }

                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row
                    style={this.state.style.rows.center}
                >
                    <DropdownItem divider />
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                marginTop: "20px",
                                marginBottom: "20px"
                            }}
                        >
                            Datos Del Credito que Solicita
                        </Label>
                    </Col>
                </Row>
                <Row
                    style={this.state.style.rows.center}
                >
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Tipo de Crédito
                            </Label>
                            <Input
                                name="IdTipoSolicitudCredito"
                                id="IdTipoSolicitudCredito"
                                value={this.state.solicitudCredito.IdTipoSolicitudCredito}
                                type="select"
                                onChange={async (event) => {
                                    let { value } = event.target;

                                    this.cambiarDatosSolicitudCredito(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                <option value={1}>Credito Individual</option>
                                <option value={2}>Credito Grupal</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Monto Solicitado
                            </Label>
                            <Input
                                name="IdMontotoSolicitudCredito"
                                id="IdMontotoSolicitudCredito"
                                value={this.state.solicitudCredito.IdMontotoSolicitudCredito}
                                type="select"
                                onChange={async (event) => {
                                    this.cambiarDatosSolicitudCredito(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {/* <option value={5000}>$5,000.00 MXN</option>
                                <option value={10000}>$10,000.00 MXN</option> */}
                                {
                                    this.state.listaMontoPago.map((value) => {
                                        return (
                                            <option value={value.IdMontoCredito}>{parseFloat(value.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 2 })} MXN</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "40%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Plazo del Crédito
                            </Label>
                            <Input
                                name="IdPlazoPagoSolicitudCredito"
                                id="IdPlazoPagoSolicitudCredito"
                                value={this.state.solicitudCredito.IdPlazoPagoSolicitudCredito}
                                type="select"
                                onChange={async (event) => {
                                    let { value } = event.target;

                                    this.cambiarDatosSolicitudCredito(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaPlazoPagoCredito.map((value) => {
                                        return (
                                            <option value={value.IdPlazoCredito}>{value.plazoCredito} ({value.descripcion})</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "20%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Forma de Pago
                            </Label>
                            <Input
                                name="IdFormaPagoSolicitudCredito"
                                id="IdFormaPagoSolicitudCredito"
                                value={this.state.solicitudCredito.IdFormaPagoSolicitudCredito}
                                type="select"
                                onChange={async (event) => {
                                    this.cambiarDatosSolicitudCredito(event);
                                }}
                                onClick={() => {
                                    if (!this.state.clienteAceptaPoliticas) {
                                        this.setState({
                                            abrirModalAceptoPrivacidadCLiente: true,
                                        });
                                    }
                                }}
                            >
                                <option value={0}>Escoge una opción</option>
                                {
                                    this.state.listaFormasPago.map((formaPago) => {
                                        return (
                                            <option value={formaPago.IdFormaPago}>{formaPago.descripcion}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {/* <Row
                    style={this.state.style.rows.center}
                >
                    <Col

                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "33%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Foto de Fachada de la Casa
                            </Label>
                            <Input
                                type="file"
                                accept="image/*"
                                capture="camera"
                                onChange={async (event) => {
                                    let file = event.target.files[0]
                                    const base64String = await ImagenTool.readFileAsBase64(file);

                                    this.setState({
                                        cliente: {
                                            ...this.state.cliente,
                                            imgFachadaCasa: base64String
                                        }
                                    });
                                }}
                            />
                            {
                                this.state.cliente.imgFachadaCasa.length === 0 ?
                                    null
                                    :
                                    <center>
                                        <img
                                            src={this.state.cliente.imgFachadaCasa.length === 0 ? imgEmpty : this.state.cliente.imgFachadaCasa}
                                            alt="No se pudo cargar la imagen"
                                            style={{
                                                marginTop: "20px",
                                                width: "100%",
                                                borderRadius: "10px"
                                            }}
                                        />
                                    </center>
                            }

                        </FormGroup>
                    </Col>
                    <Col

                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "33%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Foto con el Cliente
                            </Label>
                            <Input
                                type="file"
                                accept="image/*"
                                capture="camera"
                                onChange={async (event) => {
                                    let file = event.target.files[0]
                                    const base64String = await ImagenTool.readFileAsBase64(file);

                                    this.setState({
                                        cliente: {
                                            ...this.state.cliente,
                                            imgFotoPersona: base64String
                                        }
                                    });
                                }}
                            />
                            {
                                this.state.cliente.imgFotoPersona.length === 0 ?
                                    null
                                    :
                                    <center>
                                        <img
                                            src={this.state.cliente.imgFotoPersona.length === 0 ? imgEmpty : this.state.cliente.imgFotoPersona}
                                            alt="No se pudo cargar la imagen"
                                            style={{
                                                marginTop: "20px",
                                                width: "100%",
                                                borderRadius: "10px"
                                            }}
                                        />
                                    </center>
                            }

                        </FormGroup>
                    </Col>
                    <Col

                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            maxWidth: this.props.isDesktopDevice ? "33%" : ""
                        }}
                    >
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Foto del INE
                            </Label>

                            <Input
                                type="file"
                                accept="image/*"
                                capture="camera"
                                onChange={async (event) => {
                                    let file = event.target.files[0]
                                    const base64String = await ImagenTool.readFileAsBase64(file);

                                    this.setState({
                                        cliente: {
                                            ...this.state.cliente,
                                            imgINE: base64String
                                        }
                                    });
                                }}
                            />
                            {
                                this.state.cliente.imgINE.length === 0 ?
                                    null
                                    :
                                    <center>
                                        <img
                                            src={this.state.cliente.imgINE.length === 0 ? imgEmpty : this.state.cliente.imgINE}
                                            alt="No se pudo cargar la imagen"
                                            style={{
                                                marginTop: "20px",
                                                width: "100%",
                                                borderRadius: "10px"
                                            }}
                                        />
                                    </center>
                            }

                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row
                    style={this.state.style.rows.center}
                >
                    <DropdownItem divider />
                    <Col
                        md={this.props.isDesktopDevice ? "" : "2"}
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                color: colors.negro
                            }}
                        >
                            Declaro Bajo Protesta De Decir La Verdad Que La Información Compartida En Esta Encuesta Es Verídica Y Comprobable.
                        </Label>
                    </Col>
                </Row> */}

                <Row
                    style={this.state.style.rows.down}
                >
                    <Col
                        style={{
                            textAlign: this.props.isDesktopDevice ? "right" : "center"
                        }}
                        md={this.props.isDesktopDevice ? "" : "2"}
                    >
                        <Button
                            style={this.state.style.button}
                            onClick={() => {
                                // this.nuevaSolicitud();
                                window.location.href = "/solicitud-credito";
                            }}
                            disabled={this.state.cliente.IdCliente.length == 0 || this.state.cliente.IdCliente == "" ? false : true}
                        >
                            Nueva Solicitud
                        </Button>
                        {
                            this.props.isDesktopDevice ?
                                <Button
                                    style={this.state.style.button}
                                    onClick={() => {
                                        this.guardarCliente();
                                    }}
                                >
                                    Solicitar Crédito
                                </Button>
                                :
                                null
                        }

                    </Col>
                    {
                        !this.props.isDesktopDevice ?
                            <>
                                <Col
                                    style={{
                                        textAlign: this.props.isDesktopDevice ? "right" : "center"
                                    }}
                                    md={this.props.isDesktopDevice ? "" : "2"}
                                >
                                    <Button
                                        style={this.state.style.button}
                                        onClick={() => {
                                            this.guardarCliente();
                                        }}
                                    >
                                        Solicitar Crédito
                                    </Button>
                                </Col>
                            </>
                            :
                            null
                    }


                    <Row style={{ marginTop: "15px" }} > <Col></Col></Row>
                </Row>
                <Row style={{ marginTop: "100px" }} > <Col></Col></Row>

                <Modal
                    isOpen={this.state.abrirModalAceptoPrivacidadCLiente}
                    size="lg"
                    // size="lg"
                    style={{
                        maxWidth: "90%"
                    }}
                >
                    {/* <ModalHeader>

                    </ModalHeader> */}
                    <ModalBody>

                        <AvisoPrivacidad />
                        <Row
                            style={{
                                marginTop: "50px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <Label
                                    style={{
                                        marginRight: "30px",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        color: colors.azul
                                    }}
                                >
                                    Al hacer click en acepto estas aceptando nuestro aviso de privacidad
                                </Label>
                                <br />
                                <Label
                                    style={{
                                        marginRight: "30px",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        color: colors.azul,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        window.location.href = "/aviso-de-privacidad";
                                    }}
                                >
                                    Ver pagina de aviso de privacidad
                                </Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                fontWeight: "bold"
                            }}
                            onClick={() => {
                                this.setState({
                                    abrirModalAceptoPrivacidadCLiente: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                boxShadow: "none",
                                borderColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                            }}
                            onClick={() => {
                                this.setState({
                                    clienteAceptaPoliticas: true,
                                    abrirModalAceptoPrivacidadCLiente: false,
                                });
                            }}
                        >
                            Acepto
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        );
    }

}

export default SolicitudCredito;