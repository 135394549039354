import { Component } from "react";
import usuarioModel from "../services/User.service";
import NavBar from "../components/NavBar";
import imgFondo from "../assets/image/background/007.jpg"
import { ToastContainer, toast } from 'react-toastify';
import Table from "react-data-table-component";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import colors from "../config/colors.config";
import { IconButton } from "@material-ui/core";
import { Add, Cached, Close, Delete, Edit, ListAlt } from "@material-ui/icons";
import components from "../tools/components.tool";
import swal from "sweetalert2";
import timeTool from "../tools/time.tool";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class ListaUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estaAbiertoModalHistorialUsuario: false,
            estaAbiertoModalUsuario: false,
            listaUsuarios: [],
            listaHistorialUsuario: [],
            columnsTablaHistorialUsuario: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Fecha y Hora</p>,
                    selector: row =>
                        <label
                            title={timeTool.castFechaNacimientoEscrita(row.fecha) + " " + timeTool.formato12Horas(row.hora)}
                        >
                            {timeTool.castFechaNacimiento(row.fecha) + " " + timeTool.formato12Horas(row.hora)}
                        </label>,

                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre Completo</p>,
                    selector: row =>
                        <label
                            title={row.nombreCompletoUsuario}
                        >
                            {row.nombreCompletoUsuario}
                        </label>,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre de Usuario</p>,
                    selector: row =>
                        <label
                            title={row.username}
                        >
                            {row.username}
                        </label>,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Movimiento</p>,
                    selector: row =>
                        <label
                            title={row.descripcionTipoMovimientoUsuario}
                        >
                            {row.tipoMovimientoUsuario}
                        </label>,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Referencia</p>,
                    selector: row =>
                        <label
                            title={row.IdReferencia.length === 0 ? "Sin referencia" : row.tipoReferencia == "usu" ? (row.data.nombreCompletoUsuario + " (" + row.data.username + ")") : row.data.nombreCliente}
                        >
                            {row.IdReferencia.length === 0 ? "Sin referencia" : row.tipoReferencia == "usu" ? (row.data.nombreCompletoUsuario + " (" + row.data.username + ")") : row.data.nombreCliente}
                        </label>,

                },
            ],
            usuario: {
                IdUsuario: "",
                IdTipoUsuario: "",
                nombreCompletoUsuario: "",
                username: "",
                password: "",
                correoUsuario: "",
                checkCambiarPasswordUsuario: null,
            },
            invalidoDatosUsuario: {
                nombreCompletoUsuario: null,
                username: null,
                password: null,
            },
            columnsTablaUsuario: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre Completo</p>,
                    selector: row =>
                        <label
                            style={{
                                fontWeight: row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario ? "bold" : "",
                            }}
                            title={row.nombreCompletoUsuario + " " + row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario ? "(Mi Perfil)" : ""}
                        >
                            {row.nombreCompletoUsuario} {row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario ? "(Mi Perfil)" : ""}
                        </label>,

                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre de Usuario</p>,
                    selector: row =>
                        <label
                            style={{
                                fontWeight: row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario ? "bold" : "",
                            }}
                        >
                            {row.username}
                        </label>,

                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}></p>,
                    selector: row =>
                        <>

                            <IconButton
                                onClick={() => {
                                    this.eliminarUsuario(row);
                                }}
                                title="Eliminar usuario"
                                disabled={row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario}
                            >
                                <Delete
                                    style={{
                                        color: row.IdUsuario == cookies.get("usuario", { path: "/" }).IdUsuario ? "grey" : colors.rojoPDF
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        estaAbiertoModalHistorialUsuario: true,
                                        usuario: row
                                    });
                                    this.listarHistorialUsuario(row.IdUsuario);
                                }}
                                title="Ver historial usuario"
                            >
                                <ListAlt
                                    style={{
                                        backgroundColor: colors.azul,
                                        color: colors.blanco,
                                    }}
                                />
                            </IconButton>

                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        estaAbiertoModalUsuario: true,
                                        usuario: row,
                                    });
                                }}
                                title="Editar usuario"
                            >
                                <Edit
                                    style={{
                                        color: colors.azul
                                    }}
                                />
                            </IconButton>
                        </>,

                },
            ],
        }
    }

    componentDidMount() {

        this.props.isDesktopDevice ?
            document.body.style = `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover;`
            :
            document.body.style = `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`


        usuarioModel.getTokenUsuario();

        this.listarUsuario(true)
    }

    listarUsuario = async (data) => {
        let response = await usuarioModel.listarUsuarios();

        let { listaUsuarios } = response;

        let array = []

        for (let i = 0; i < listaUsuarios.length; i++) {
            if (listaUsuarios[i].IdUsuario !== "usu-9dc18a96-f92c-11ed-bef6-f01faf438217") {
                if (listaUsuarios[i].estaEliminado == 0) {
                    array.push(listaUsuarios[i]);
                }
            }

        }

        await this.setState({
            listaUsuarios: array
        });
        if (data) {
            toast.info(`Se listaron ${listaUsuarios.length} usuarios`);
        }

    }

    cambiarDatosUsuario = async ({ target }) => {

        let { name, value } = target;

        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value
            },
            invalidoDatosUsuario: {
                ...this.state.invalidoDatosUsuario,
                [name]: value.length === 0 ? true : false
            }
        });
    }

    guardarUsuario = async () => {
        let response = await usuarioModel.guardarUsuario(this.state.usuario);
        let { usuario } = response;
        this.setState({
            usuario
        });
        toast.success("Usuario guardado");
        this.listarUsuario(false);
    }

    eliminarUsuario = async (usuario) => {
        swal.fire({
            icon: "question",
            title: `¿Eliminar Usuario?`,
            text: `¿Estas seguro de eliminar al usuario ${usuario.nombreCompletoUsuario}?`,
            showCancelButton: true,
            confirmButtonText: "Si, Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            confirmButtonColor: colors.azul,
        }).then(async (response) => {
            if (response.isConfirmed) {
                let response = await usuarioModel.eliminarUsuario(usuario.IdUsuario);
                toast.success("Usuario Eliminado");
                this.listarUsuario(false);
            }
        });

    }

    obtenerDataReferencia = async ({ tipoReferencia, IdReferencia }) => {
        if (tipoReferencia == 1) {
            let response = await usuarioModel.usuarioPorId(IdReferencia)

            return response.usuario.nombreCompletoUsuario;
        }
        if (tipoReferencia == 2) {
            return "";
        }

    }

    listarHistorialUsuario = async (IdUsuario) => {
        let response = await usuarioModel.historialUsuario(IdUsuario);

        let { listaHistorialUsuario } = response;

        await this.setState({
            listaHistorialUsuario
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />
                <div
                    style={{
                        marginTop: this.props.isDesktopDevice ? "100px" : "20px",
                        backgroundColor: colors.blanco,
                        marginRight: this.props.isDesktopDevice ? "50px" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "50px" : "5px",
                        borderRadius: "8px"
                    }}
                >
                    <Row>
                        <Col >
                            <Label
                                style={{
                                    marginTop: 15,
                                    fontWeight: "bold",
                                    fontSize: 30,
                                    color: colors.azul,
                                    marginLeft: 10,
                                }}
                            >
                                {this.props.isDesktopDevice ? "Lista de Usuarios" : "Usuarios"}
                            </Label>
                        </Col>
                        <Col
                            style={{
                                textAlign: "right",
                                marginRight: "15px"
                            }}
                        >
                            <IconButton
                                style={{
                                    marginTop: "15px",
                                    color: colors.azul, backgroundColor: "#F2F2F2",
                                    marginRight: 7
                                }}
                                onClick={async () => {
                                    await this.setState({
                                        estaAbiertoModalUsuario: true,
                                        usuario: {
                                            IdUsuario: "",
                                            IdTipoUsuario: "",
                                            nombreCompletoUsuario: "",
                                            username: "",
                                            password: "",
                                            correoUsuario: "",
                                            checkCambiarPasswordUsuario: 0
                                        },
                                    });
                                }}
                            >
                                <Add />
                            </IconButton>
                            <IconButton
                                style={{
                                    marginTop: "15px",
                                    color: colors.azul, backgroundColor: "#F2F2F2",
                                }}
                                onClick={() => {
                                    this.listarUsuario(true);
                                    toast.success("Tabla actualizada");
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>

                    <Row style={{ marginRight: 5, marginLeft: 5, marginTop: 20, borderRadius: 8, height: "70vh", overflowY: "scroll" }}>
                        <Table
                            columns={this.state.columnsTablaUsuario}
                            data={this.state.listaUsuarios}
                            // title={<label style={{ color: colors.verde_1, fontWeight: "bold" }} >{`Hay ${this.state.listaClientes.length} clientes encontrados`}   </label>}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay usuarios registrados</p>}
                            // pagination
                            dense
                        />
                    </Row>
                </div>

                <Modal
                    isOpen={this.state.estaAbiertoModalUsuario}
                    onOpened={() => {
                        components.fucusElement("nombreCompletoUsuario");
                        this.setState({
                            usuario: {
                                ...this.state.usuario,
                                checkCambiarPasswordUsuario: this.state.usuario.IdUsuario.length === 0 ? true : false
                            }
                        });
                    }}
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul,

                        }}
                        close={
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <IconButton
                                        style={{ color: colors.azul, backgroundColor: "#F2F2F2", }}
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalUsuario: false,
                                            });
                                        }}
                                    >
                                        <Close
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                    >
                        <Label style={{ color: colors.blanco, fontWeight: "bold", }}>
                            Datos del Usuario
                        </Label>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.azul, fontWeight: "bold", }}>
                                        Nombre del Completo
                                    </Label>
                                    <Input
                                        type="text"
                                        id="nombreCompletoUsuario"
                                        name="nombreCompletoUsuario"
                                        value={this.state.usuario.nombreCompletoUsuario}
                                        invalid={this.state.invalidoDatosUsuario.nombreCompletoUsuario}
                                        onChange={(event) => {
                                            // if (!isNaN(value) && value.length <= 5 && value.charAt(value.length - 1) !== " " && value.charAt(value.length - 1) !== "-" && value.charAt(value.length - 1) !== "+" && value.charAt(value.length - 1) !== ".") {
                                            this.cambiarDatosUsuario(event)
                                            // }

                                        }}
                                    >
                                    </Input>
                                    <FormFeedback>
                                        Este campo no puede estar vació
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{ color: colors.azul, fontWeight: "bold", }}>
                                        Nombre de Usuario
                                    </Label>
                                    <Input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={this.state.usuario.username}
                                        invalid={this.state.invalidoDatosUsuario.username}
                                        onChange={(event) => {
                                            // if (!isNaN(value) && value.length <= 5 && value.charAt(value.length - 1) !== " " && value.charAt(value.length - 1) !== "-" && value.charAt(value.length - 1) !== "+" && value.charAt(value.length - 1) !== ".") {
                                            this.cambiarDatosUsuario(event)
                                            // }

                                        }}
                                    >
                                    </Input>
                                    <FormFeedback>
                                        Este campo no puede estar vació
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            this.state.usuario.IdUsuario.length > 0 ?
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label style={{ color: colors.azul, fontWeight: "bold", }}>
                                                Cambiar Contraseña Usuario
                                            </Label>
                                            <Input
                                                style={{
                                                    marginLeft: "5px",
                                                    marginTop: "6px",
                                                }}
                                                type="checkbox"
                                                checked={this.state.usuario.checkCambiarPasswordUsuario}
                                                onChange={async () => {
                                                    await this.setState({
                                                        usuario: {
                                                            ...this.state.usuario,
                                                            checkCambiarPasswordUsuario: this.state.usuario.checkCambiarPasswordUsuario === 1 ? 0 : 1
                                                        }

                                                    });

                                                    if (this.state.usuario.checkCambiarPasswordUsuario === 1) {
                                                        components.fucusElement("password");
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                null
                        }

                        {
                            this.state.usuario.checkCambiarPasswordUsuario === 1 || this.state.usuario.IdTipoUsuario.length == 0 ?
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label style={{ color: colors.azul, fontWeight: "bold", }}>
                                                Contraseña de Usuario
                                            </Label>
                                            <Input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={this.state.usuario.password}
                                                // disabled={(this.state.usuario.checkCambiarPasswordUsuario === 1 ? false : true)}
                                                invalid={this.state.invalidoDatosUsuario.password}
                                                onChange={(event) => {
                                                    // if (!isNaN(value) && value.length <= 5 && value.charAt(value.length - 1) !== " " && value.charAt(value.length - 1) !== "-" && value.charAt(value.length - 1) !== "+" && value.charAt(value.length - 1) !== ".") {
                                                    this.cambiarDatosUsuario(event)
                                                    // }

                                                }}
                                            >
                                            </Input>
                                            <FormFeedback>
                                                Este campo no puede estar vació
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                null
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.azul,
                                width: 130
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalUsuario: false,
                                    usuario: {
                                        IdUsuario: "",
                                        IdTipoUsuario: "",
                                        nombreCompletoUsuario: "",
                                        username: "",
                                        password: "",
                                        correoUsuario: ""
                                    }
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.azul,
                                width: 130
                            }}
                            onClick={() => {
                                this.guardarUsuario();
                            }}
                        >
                            {this.state.usuario.IdTipoUsuario == "" ? "Guardar" : "Actualizar"}
                        </Button>
                    </ModalFooter>
                </Modal >
                <Modal
                    isOpen={this.state.estaAbiertoModalHistorialUsuario}
                    size="lg"
                    style={{
                        maxWidth: this.props.isDesktopDevice ? "90%" : "95%"
                    }}
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul,
                            color: colors.blanco,
                            fontWeight: "bold",
                            fontSize: 28
                        }}
                        close={
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <IconButton
                                        style={{ color: colors.azul, backgroundColor: "#F2F2F2", marginRight: 7 }}
                                        onClick={() => {
                                            this.listarHistorialUsuario(this.state.usuario.IdUsuario);
                                        }}
                                    >
                                        <Cached
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{ color: colors.azul, backgroundColor: "#F2F2F2", }}
                                        onClick={() => {
                                            this.setState({
                                                estaAbiertoModalHistorialUsuario: false,
                                            });
                                        }}
                                    >
                                        <Close
                                            style={{
                                                color: colors.azul
                                            }}
                                        />
                                    </IconButton>
                                </Col>
                            </Row>
                        }
                    >
                        {this.props.isDesktopDevice ? `Historial de ${this.state.usuario.nombreCompletoUsuario} (${this.state.listaHistorialUsuario.length} movimientos encontrados)` : `${this.state.usuario.nombreCompletoUsuario}`}
                    </ModalHeader >
                    <ModalBody
                        style={{
                            overflowY: "scroll",
                            height: "500px"
                        }}
                    >
                        <Row>
                            <Col>
                                <Table
                                    // title={`${this.state.listaHistorialUsuario.length} movimientos encontrados`}
                                    columns={this.state.columnsTablaHistorialUsuario}
                                    data={this.state.listaHistorialUsuario}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>No hay registros de este usuario</p>}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.azul,
                            }}
                            onClick={() => {
                                this.setState({
                                    estaAbiertoModalHistorialUsuario: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal >
            </>
        );
    }
}

export default ListaUsuario;