import { useEffect } from "react";
import NavBarComputer from "./NavBarComputer";
import NavBarMovil from "./NavBarMovil";
import device from "../config/device.config";
import { useState } from "react";
// import userModel from "../models/user.model";

const NavBar = (props) => {

    const [data, setData] = useState({
        isTablet: null ,
        isMovil : null ,
        isDesktop : null ,
    });    
   
    useEffect(() => {
        let data = device.getData();
        setData(data);
    }, []);

    return (
        <>
            {data.isDesktop ?
                <NavBarComputer/>
                :
                <NavBarMovil/>
            }
        </>
    );
}

export default NavBar;