const Colors = {
    verde_1 : "#0DB02B",
    verde_2 : "#61BF1A",
    verde_3 : "#8FD400",
    azul : "#00669E",
    azul_1 : "#4799C7",
    azul_link : "#0563C1",
    blanco: "#FFFFFF",
    negro: "#000",
    rojo : "red",    
    verdeWhatsApp: "#25D366",    
    azul_link: "#0563C1",
    blanco: "#FFFFFF",    
    gris: "#B5B5B5",
    gris_1: "grey",
    gris_2: "#F2F2F2",
    rojoPDF: "#F10002",
    azulDescarga: "#1A73E8",
    roundcube: {
        blanco: "#FFFFFF",
        gris_1: "#E5E5E5",
        gris_2: "#404F54",
        celeste: "#37BEFF",
        azul: "#08193E",
    },
    whatsApp: {
        blanco: "#FFFFFF",
        celeste: "#34B7F1",
        verde_1: "#25D366",
        verde_2: "#128C7E",
        verde_3: "#075E54",
    },
    outlook: {
        azul: "#0178D4",
        celeste: "#B3D6F2",
        blanco: "#FFFFFF",
        gris: "#F2F2F2",
    }

}

// const Colors = {
//     verde_1: "#00669E",
//     verde_2: "#61BF1A",
//     verde_3: "#8FD400",
//     verdeWhatsApp: "#25D366",
//     azul: "#0DB02B",
//     azul_link: "#0563C1",
//     blanco: "#FFFFFF",
//     negro: "#000",
//     gris: "#B5B5B5",
//     gris_1: "grey",
//     gris_2: "#F2F2F2",
//     rojoPDF: "#F10002",
//     azulDescarga: "#1A73E8",
//     roundcube: {
//         blanco: "#FFFFFF",
//         gris_1: "#E5E5E5",
//         gris_2: "#404F54",
//         celeste: "#37BEFF",
//         azul: "#08193E",
//     },
//     whatsApp: {
//         blanco: "#FFFFFF",
//         celeste: "#34B7F1",
//         verde_1: "#25D366",
//         verde_2: "#128C7E",
//         verde_3: "#075E54",
//     },
//     outlook: {
//         azul: "#0178D4",
//         celeste: "#B3D6F2",
//         blanco: "#FFFFFF",
//         gris: "#F2F2F2",
//     }

// }

export default Colors;