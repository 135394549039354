import axios from "axios";
import API from '../config/network.config';
import swal2 from 'sweetalert2';
import colors from "../config/colors.config";
// import moment from "moment/moment";
import Cookies from "universal-cookie";


const cookies = new Cookies();
const urlApi = API + "/solicitud-credito";
const mensajeError = (error) => {
    
    let menssage = error.config.url + ": " + error.message

    
    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {

    let menssage = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(() => {
        window.location.href = "/";
    });
}


const getSolicitudesCreditoPorIdCliente = async (IdCliente) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar-por-cliente/${IdCliente}`,   
            headers:{
                token : await cookies.get("usuario", {path:"/"}).token
            }   
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const solicitudCreditoAprobarRechazarCancelar = async (solicitudCredito) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/solicitud-credito-aprobar-rechazar-cancelar`,   
            headers:{
                token : await cookies.get("usuario", {path:"/"}).token
            },
            data:{
                solicitudCredito,
                myId: await cookies.get("usuario", {
                    path: "/"
                }).IdUsuario 
            }   
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const listarHistorialSolicitudCredito = async (IdSolicitudCredito) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/historial-solicitud-credito-por-Id/${IdSolicitudCredito}`,   
            headers:{
                token : await cookies.get("usuario", {path:"/"}).token
            }, 
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}



const solicitudCredito = {
    getSolicitudesCreditoPorIdCliente,
    solicitudCreditoAprobarRechazarCancelar,
    listarHistorialSolicitudCredito
}

export default solicitudCredito;