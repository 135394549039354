const castFormatoMoneda = (data) =>{
    
    
    if(data == undefined){
        data = 0;
    }
    let moneda = data.toLocaleString("en", {
        style: "currency",
        currency: "MXN"
    });
       
    return moneda.replace("MX", "MX ");
}

const numeroTools = {
    castFormatoMoneda,
}

export default numeroTools;