import axios from "axios";
import API from '../config/network.config';
import swal2 from 'sweetalert2';
import colors from "../config/colors.config";
import serverConfig from "../config/server.config.json";
import Cookies from "universal-cookie";
import md5 from "md5";

const cookies = new Cookies();
const urlApi = API + "/usuario";

const mensajeError = (error) => {

    let menssage = error.config.url + ": " + error.message


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensaje403 = () => {

    let message = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${message}</p>`
    }).then(() => {
        window.location.href = "/";
    });

}

const cifrarUsuario = async (usuario) => {

    let primeraParte = await serverConfig.server.secrect.substring(0, 31);
    let segundaParte = await serverConfig.server.secrect.substring(32, 64);
    usuario = {
        ...usuario,
        password: primeraParte + md5(usuario.password) + segundaParte
    }

    return usuario
}

const loguearUsuario = async (usuario) => {

    usuario = await cifrarUsuario(usuario)

    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/loguear`,
            data: {
                usuario
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const listarUsuarios = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const guardarUsuario = async (usuario) => {
    if (usuario.checkCambiarPasswordUsuario) {
        usuario = await cifrarUsuario(usuario)
    }


    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/guardar`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {
                usuario,
                myId: await cookies.get("usuario", {
                    path: "/"
                }).IdUsuario
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const eliminarUsuario = async (IdUsuario) => {



    let response;
    let data = null;

    try {
        response = await axios({
            method: "DELETE",
            url: `${urlApi}/eliminar/${IdUsuario}`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token,

            },
            data: {
                myId: await cookies.get("usuario", {
                    path: "/"
                }).IdUsuario
            }
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const historialUsuario = async (IdUsuario) => {



    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/historial/${IdUsuario}`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const usuarioPorId = async (IdUsuario) => {



    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/usuario/${IdUsuario}`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
        });
        data = response.data;
    } catch (error) {
        mensajeError(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const getTokenUsuario = async () => {
    let token = await cookies.get("token", {
        path: "/"
    })

    if (token == undefined) {
        swal2.fire({
            icon: 'warning',
            title: '¡Acceso Denegado!',
            text: "Debes de iniciar sesión para poder usar el sistema",
            //timer: "5000",
            confirmButtonColor: colors.azul,
            confirmButtonText: 'Okey',
            allowEscapeKey: true,
            timerProgressBar: true,
            // footer: `<p style="text-align: center; color: ${colors.rojo};">${message}</p>`
        }).then(() => {
            window.location.href = "/";
        });
    }

}

const getUsuarioLocal = () => {
    let usuario = cookies.get("usuario", {
        path: "/"
    })
    return usuario;
}

const usuarioModel = {
    loguearUsuario,
    getTokenUsuario,
    listarUsuarios,
    guardarUsuario,
    eliminarUsuario,
    historialUsuario,
    usuarioPorId,
    getUsuarioLocal
}

export default usuarioModel;