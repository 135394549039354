import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import LoginUser from "./pages/LoginUser";
import ListaClientes from "./pages/ListaClientes";
import ListaUsuarios from "./pages/ListaUsuarios";
import SolicitudCredito from "./pages/SolicitudCredito";
import ListaFormularioCliente from "./pages/ListaFormularioClientes";

/****************************************************/
import NotFound404 from "./pages/template/NotFound404";
import EnContruccion from "./pages/template/EnContruccion";

function App() {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isDesktopDevice = !regexp.test(details); 
  // let isMobileDevice = !isDesktopDevice;
  return (  
    <>
    <entrada aceptar="imagen/*" id="icono-botón-archivo" tipo="archivo" captura="entorno"/>
    <Router>
      <Routes>          
        <Route exact path="/" element={<LoginUser  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/lista-clientes" element={<ListaClientes  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/lista-usuario" element={<ListaUsuarios  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/solicitud-credito" element={<SolicitudCredito  isDesktopDevice={isDesktopDevice} />}/>   
        <Route exact path="/formulario-clientes" element={<ListaFormularioCliente  isDesktopDevice={isDesktopDevice} />}/>           
        <Route path='*' element={<NotFound404 isDesktopDevice={isDesktopDevice} />}/> 
      </Routes>
    </Router>
    </>
  );
}

export default App;
