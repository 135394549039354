import NavBar from "../components/NavBar";
import Table from "react-data-table-component";
import { Button, Col, DropdownItem, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input, Row } from "reactstrap";
import colors from "../config/colors.config";
import { useState } from "react";
import { useEffect } from "react";
import ClienteService from "../services/Cliente.service";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress, FormGroup, IconButton, Popover } from "@material-ui/core";
import { Cached, Close, Delete, Email, Visibility, WhatsApp } from "@material-ui/icons";
import timeTool from "../tools/time.tool";
import Swal from "sweetalert2";
import UserServices from "../services/User.service";
import Fade from 'react-reveal/Fade';

import img08 from "../assets/image/background/008.jpg"
import imgGmail from "../assets/image/services/gmail.png"
import imgRoundcube from "../assets/image/services/roundcube.png"
import DeviceData from "../config/device.config";

const ListaFormularioClientes = () => {
    const [totales, setTotatles] = useState({
        atendidos: 0,
        noAtendidos: 0,
    });
    const [fechaHoy, setFechaHoy] = useState(timeTool.getFechaHoy());
    const [listaRegistroFormulario, setListaRegistroFormulario] = useState([])
    const [estaAbiertoModalFormulario, setEstaAbiertoModalFormulario] = useState(false)
    const [isHoveredButtonGmil, setIsHoveredButtonGmil] = useState(false)
    const [estaAbiertoModalOpcionCorreo, setEstaAbiertoModalOpcionCorreo] = useState(false)
    const [columnasRegistroFormulario, setColumnasRegistroFormulario] = useState([
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Hora</p>,
            width: "120px",
            selector: row =>
                <label
                    title={timeTool.castFechaNacimientoEscrita(row.fecha)}
                >
                    {timeTool.formato12Horas(row.hora)}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Asunto</p>,
            selector: row =>
                <label>
                    {row.asunto}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Nombre Completo</p>,
            selector: row =>
                <label>
                    {row.nombreCompleto}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Es Cliente</p>,
            selector: row =>
                <label>
                    {row.IdCliente.length === 0 ? "No" : "Si"}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Estatus</p>,
            selector: row =>
                <label
                    style={{
                        color: row.IdStatus === 1 ? colors.gris : colors.azul,
                        fontWeight: "bold"
                    }}
                >
                    {row.IdStatus == "1" ? "No Atendido" : "Ya Atendido"}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: "14px", color: colors.azul }}>Acciones</p>,
            selector: row =>
                <>
                    <IconButton
                        style={{ backgroundColor: colors.blanco, color: colors.rojoPDF }}
                        onClick={() => {
                            console.log(row);
                            Swal.fire({
                                icon: "question",
                                title: `Eliminar Formulario`,
                                text: `¿Estas seguro de eliminar este formulario?, no podrás recuperar la información una vez eliminado`,
                                showCancelButton: true,
                                confirmButtonText: "Si, Eliminar",
                                cancelButtonText: "Cancelar",
                                reverseButtons: true,
                                confirmButtonColor: colors.rojoPDF,
                            }).then(async (response) => {
                                if (response.isConfirmed) {

                                    let response = await ClienteService.eliminarFormulario(row.IdFormularioContacto);
                                    if (response.status === 200) {
                                        toast.success("Eliminado");
                                        getListaRegistroFormulario(fechaHoy, tipoFormularios);
                                    } else {
                                        toast.error("Error en la petición");
                                    }

                                }
                            });
                        }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton
                        style={{ backgroundColor: colors.blanco, color: colors.azul }}
                        onClick={() => {
                            setFormulario(row);
                            setEstaAbiertoModalFormulario(true);
                        }}
                    >
                        <Visibility />
                    </IconButton>
                </>,

        },
    ])
    const [formulario, setFormulario] = useState({
        asunto: "",
        nombreCompleto: "",
        telefono: "",
        correo: "",
        mensaje: "",
        IdCliente: "",
        IdStatus: 0,
    });
    const [device, setDevice] = useState({
        isTablet: null,
        isMovil: null,
        isDesktop: null,
    });

    const [usuario, setUsuario] = useState({
        IdUsuario: 0,
        IdTipoUsuario: 0,
        tipoUsuario: 0,
        nombreCompletoUsuario: "",
        Username: "",
        correoUsuario: "",
        tieneAbilitadoServicioGmail: -1,
    });

    const [tipoFormularios, setTipoFormularios] = useState(0);

    useEffect(() => {
        // console.clear();
        let usuario = UserServices.getUsuarioLocal();
        setUsuario(usuario);
        let fechaHoy = timeTool.getFechaHoy();
        setFechaHoy(fechaHoy);
        getListaRegistroFormulario(fechaHoy, 0);
        let data = DeviceData.getData();
        setDevice(data);
    }, []);



    const getListaRegistroFormulario = async (fechaHoy, IdStatus) => {
        console.log("Obteniendo...", fechaHoy, IdStatus)
        if (fechaHoy.length === 0) { toast.warning("Especifica una fecha para buscar"); return; }
        
        let response = await ClienteService.getListaFormulario(fechaHoy, IdStatus);
        console.log("flag")
        console.log(response)
        if (response === undefined) { toast.error("Algo salio mal"); return; }
        console.log(response)
        let { status, error } = response;
        if (!error) {
            if (status === 200) {
                let { listaRegistroFormulario } = response;
                setListaRegistroFormulario(listaRegistroFormulario)
            } else {
                toast.error("Error en la petición");
            }
        } else {
            toast.error("Error en la conexión");
        }
    }

    const getCadaTipoListaRegistro = () => {
        return {
            atendidos: listaRegistroFormulario.filter(item => item.IdStatus === 2).length,
            noAtendidos: listaRegistroFormulario.filter(item => item.IdStatus === 1).length
        }
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <NavBar />
            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    background: `url(${img08}) no-repeat center center/cover fixed`,
                }}
            >
                <div style={{ height: device.isDesktop ? "10vh" : 30 }} ></div>
                <div
                    style={{
                        marginRight: device.isDesktop ? 50 : 5,
                        marginLeft: device.isDesktop ? 50 : 5,
                        backgroundColor: colors.blanco,
                        borderRadius: 10,
                        padding: 10,
                    }}
                >
                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            marginTop: 10
                        }}
                    >
                        <Col>
                            <Label style={{ fontWeight: "bold", color: colors.azul, fontSize: 30 }} >
                                Tabla de Registro
                            </Label>
                        </Col>
                        <Col
                            style={{
                                textAlign: "right"
                            }}
                        >
                            <IconButton
                                style={{ backgroundColor: colors.blanco, color: colors.azul }}
                                title="Actualizar tabla"
                                onClick={() => {

                                    getListaRegistroFormulario(fechaHoy, tipoFormularios);
                                    toast.info("Tabla Actualizada");
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            marginTop: 10
                        }}
                    >
                        <FormGroup
                            style={{
                                marginLeft: 10,
                                //marginTop: 10,
                                width: 470
                            }}
                        >
                            <Label style={{ fontWeight: "bold", color: colors.azul, fontSize: 18 }} >
                                Selecciona de que dia quieres ver los registros
                            </Label>
                            <Row>
                                <Col>
                                    <Input
                                        style={{
                                            width: 200,
                                            marginRight: 5
                                        }}
                                        type="date"
                                        value={fechaHoy}
                                        onChange={({ target }) => {
                                            let { value } = target;
                                            setFechaHoy(value)
                                            getListaRegistroFormulario(value, tipoFormularios)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        style={{
                                            width: 200
                                        }}
                                        type="select"
                                        value={tipoFormularios}
                                        onChange={({ target }) => {
                                            let { value } = target;
                                            setTipoFormularios(value);
                                            console.log(value);
                                            getListaRegistroFormulario(fechaHoy, value);
                                            let totales = getCadaTipoListaRegistro();
                                            setTotatles(totales);
                                        }}
                                    >
                                        <option value={0}>Todos</option>
                                        <option value={1}>No atendidos</option>
                                        <option value={2}>Atendidos</option>
                                    </Input>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Row>
                    {/* <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            marginTop: 20,
                        }}
                    >
                        <Label id="label" style={{ fontWeight: "bold", color: colors.azul, fontSize: 18, marginLeft: 10 }} onChange={() => { console.log("as"); }} >
                            Hay
                            {
                                tipoFormularios == 0 ?
                                    ` ${listaRegistroFormulario.length} formularios en total ${totales.atendidos} atendidos y ${totales.noAtendidos} no atendidos`
                                    :
                                    ` ${listaRegistroFormulario.length} formularios en total`
                            }
                        </Label>
                    </Row> */}
                    <Row
                        style={{
                            overflowY: "scroll",
                            marginRight: 0,
                            marginLeft: 0,
                            marginTop: 20,
                            height: "65vh",
                        }}
                    >
                        <Table
                            columns={columnasRegistroFormulario}
                            data={listaRegistroFormulario}
                            noDataComponent={<p style={{ fontWeight: "bold", color: colors.azul, fontSize: 18 }}>No hay registros del dia de hoy</p>}
                            dense
                        />
                    </Row>
                </div>
            </section>
            <Modal
                isOpen={estaAbiertoModalFormulario}
                size="lg"
                onOpened={() => {
                    if (formulario.IdCliente.length === 0) return;
                    /** Lógica para obtener los datos del cliente si es que esta dado de alta */
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blanco, color: colors.azul }}
                            onClick={() => {
                                setEstaAbiertoModalFormulario(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul,
                        color: colors.blanco
                    }}
                >
                    <Label
                        style={{
                            fontWeight: "bold",
                            fontSize: 24
                        }}
                    >
                        Datos
                    </Label>
                </ModalHeader>
                <ModalBody
                    style={{
                        maxHeight: "67vh",
                        // height: "67vh",
                        overflowY: "scroll",
                    }}
                >
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18
                            }}
                        >
                            Asunto
                        </Label>
                        <Label
                            style={{
                                fontSize: 16
                            }}
                        >
                            {formulario.asunto}
                        </Label>
                    </Row>
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18
                            }}
                        >
                            Nombre Completo
                        </Label>
                        <Label
                            style={{
                                fontSize: 16
                            }}
                        >
                            {formulario.nombreCompleto}
                        </Label>
                    </Row>
                    <Row>
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: 18
                                }}
                            >
                                Teléfono
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16
                                }}
                            >
                                {formulario.telefono}
                            </Label>
                        </Col>
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: 18
                                }}
                            >
                                Correo
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16
                                }}
                            >
                                {formulario.correo}
                            </Label>
                        </Col>
                    </Row>
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18
                            }}
                        >
                            Mensaje
                        </Label>
                        <p
                            style={{
                                fontSize: 16,
                                textAlign: "justify",
                            }}
                        >
                            {formulario.mensaje}
                        </p>
                    </Row>
                    <DropdownItem divider />
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18
                            }}
                        >
                            Cambiar Estatus
                        </Label>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                        fontSize: 16
                                    }}
                                >
                                    Estatus
                                </Label>
                                <Input
                                    value={formulario.IdStatus}
                                    type="select"
                                    style={{
                                        width: "33%"
                                    }}
                                    onChange={({ target }) => {
                                        let { value } = target;
                                        setFormulario({
                                            ...formulario,
                                            IdStatus: value
                                        });
                                    }}
                                >
                                    <option value={1}>Sin atender</option>
                                    <option value={2}>Atendido</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 10
                        }}
                    >
                        <Col
                            style={{
                                textAlign: "right"
                            }}
                        >
                            <Button
                                style={{
                                    boxShadow: "none",
                                    backgroundColor: colors.verde_1,
                                    borderColor: colors.blanco,
                                    fontWeight: "bold"
                                }}
                                onClick={async () => {

                                    let response = await ClienteService.formulario_cambiar_status(formulario);
                                    if (response === undefined) { toast.error("Algo salio mal"); return; }
                                    let { status, error } = response;
                                    if (!error) {
                                        if (status === 200) {
                                            toast.success(`Estatus actualizado a ${formulario.IdStatus === 1 ? "No atendido" : "Atendido"}`);
                                            getListaRegistroFormulario(fechaHoy, tipoFormularios)
                                        } else {
                                            toast.error("Error en la petición");
                                        }
                                    } else {
                                        toast.error("Error en la conexión");
                                    }
                                }}
                            >
                                Guardar
                            </Button>
                        </Col>
                    </Row>
                    {/* {
                        formulario.IdCliente.length === 0 ?
                            <>
                                <DropdownItem divider />
                                < Row >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.gris_1,
                                            fontSize: 18,
                                            // textShadow: `-0.5px -0.5px 0 ${colors.azul}, 0.5px -0.5px 0 ${colors.azul}, -0.5px 0.5px 0 ${colors.azul}, 0.5px 0.5px 0 ${colors.azul}`,
                                        }}
                                    >
                                        Esta persona no es un cliente registrado
                                    </Label>
                                </Row>
                            </>
                            :
                            null
                    } */}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18,
                                // textShadow: `-0.5px -0.5px 0 ${colors.azul}, 0.5px -0.5px 0 ${colors.azul}, -0.5px 0.5px 0 ${colors.azul}, 0.5px 0.5px 0 ${colors.azul}`,
                            }}
                        >
                            Selecciona una forma de responder
                        </Label>
                        <br />
                        <div style={{ textAlign: "right", }} >
                            <IconButton
                                style={{
                                    color: colors.whatsApp.blanco,
                                    backgroundColor: colors.whatsApp.verde_1,
                                    marginRight: 20
                                }}
                                onClick={() => {
                                    window.open(`https://web.whatsapp.com/send?phone=52${formulario.telefono}`);
                                }}
                            >
                                <WhatsApp />
                            </IconButton>
                            <IconButton
                                style={{
                                    color: colors.outlook.blanco,
                                    backgroundColor: colors.outlook.azul,
                                }}
                                onClick={async () => {
                                    // let texto = "123"
                                    // navigator.clipboard.writeText(texto)
                                    // .then(function() {
                                    //   console.log('Texto copiado al portapapeles: ' + texto);
                                    // })
                                    // .catch(function(err) {
                                    //   console.error('No se pudo copiar el texto: ', err);
                                    // });
                                    // window.open(`https://server239.web-hosting.com:2096/`);
                                    // setEstaAbiertoModalOpcionCorreo(true);
                                    window.open(`https://server239.web-hosting.com:2096/`);
                                    await navigator.clipboard.writeText(formulario.correo + "");
                                }}
                            >
                                <Email />
                            </IconButton>
                        </div>
                    </div>
                </ModalFooter>
            </Modal >
            <Modal
                isOpen={estaAbiertoModalOpcionCorreo}
                style={{
                    height: "auto"
                }}
                onOpened={() => {
                    let usuario = UserServices.getUsuarioLocal();
                    setUsuario(usuario);
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blanco, color: colors.azul }}
                            onClick={() => {
                                setEstaAbiertoModalOpcionCorreo(false);
                                setIsHoveredButtonGmil(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul,
                        color: colors.blanco
                    }}
                >
                    <Label
                        style={{
                            fontWeight: "bold",
                            fontSize: 24
                        }}
                    >
                        Servicios de Correo
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: 18,
                                // textShadow: `-0.5px -0.5px 0 ${colors.azul}, 0.5px -0.5px 0 ${colors.azul}, -0.5px 0.5px 0 ${colors.azul}, 0.5px 0.5px 0 ${colors.azul}`,
                            }}
                        >
                            Selecciona el servicio de Correo para contestar
                        </Label>
                    </Row>
                    <Row
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <Col
                            style={{
                                textAlign: "left"
                            }}
                        >
                            <Button
                                style={{
                                    boxShadow: "none",
                                    backgroundColor: colors.gris_2,
                                    borderColor: colors.blanco,
                                    borderRadius: 100,
                                    height: 60,
                                    width: 60,
                                    marginRight: 20
                                }}
                                onClick={() => {
                                    if (usuario.tieneAbilitadoServicioGmail === 0) { setIsHoveredButtonGmil(true); return };
                                    window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${formulario.correo}&su=${formulario.asunto}`);

                                }}
                                title={usuario.tieneAbilitadoServicioGmail === 1 ? "" : "No tienes habilitado el mandar correo por GMail"}
                            >
                                <img
                                    src={imgGmail}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        height: 30,
                                        width: 30,
                                        opacity: usuario.tieneAbilitadoServicioGmail === 1 ? 1 : 0.5
                                    }}
                                />
                            </Button>
                            <Button
                                style={{
                                    boxShadow: "none",
                                    backgroundColor: colors.gris_2,
                                    borderColor: colors.blanco,
                                    borderRadius: 100,
                                    height: 60,
                                    width: 60
                                }}
                                onClick={() => {
                                    window.open(`https://server239.web-hosting.com:2096/`);
                                }}
                            >
                                <img
                                    src={imgRoundcube}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        height: 30,
                                        width: 30,
                                    }}
                                />
                            </Button>
                        </Col>
                    </Row>
                    {
                        isHoveredButtonGmil ?
                            usuario.tieneAbilitadoServicioGmail === 0 ?
                                <>
                                    <Fade top>
                                        <DropdownItem divider />
                                        <div
                                            style={{
                                                textAlign: "left"

                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.gris_1,
                                                    fontSize: 18,
                                                    // textShadow: `-0.5px -0.5px 0 ${colors.azul}, 0.5px -0.5px 0 ${colors.azul}, -0.5px 0.5px 0 ${colors.azul}, 0.5px 0.5px 0 ${colors.azul}`,
                                                }}
                                            >
                                                No tienes habilitado el mandar correo por GMail
                                            </Label>

                                        </div>
                                    </Fade>
                                </>
                                :
                                null
                            :
                            null
                    }
                </ModalBody>
            </Modal >
        </>
    );
}

export default ListaFormularioClientes;