import { Component } from "react";
import colors from "../config/colors.config"
import { Col, Label, Row } from "reactstrap";
import pdf from "../assets/file/pdf/AVISO_DE_PRIVACIDAD_TZEDAKA.pdf";
class AvisoPrivacidad extends Component {

    constructor(props) {
        super(props);
        this.state = {
            styleLabelTitulo: {
                fontSize: "24px",
                fontWeight: "bold",
                color: "#67677F"
            },
            style: {
                link: {
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: colors.azul_link
                }
            }
        }
    }

    render() {
        return (
            <div
            
            style={{
                overflowY: "scroll",
                height: "550px"
            }}
       
            >
                <div
                    style={{
                        
                        backgroundColor: "rgba(0,102,158,0.75)",
                        backgroundColor: colors.verde_1,
                    }}
                >
                    <Row
                        style={{
                            marginRight: "5px",
                            marginLeft: "5px",
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "30px",
                                color: colors.blanco,
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize:  "34px",
                                    fontWeight: "bold"
                                }}
                            >
                                Aviso de Privacidad
                            </Label>
                            <br />
                            <Label
                                style={{
                                    marginTop : "10px",
                                    fontSize: "20px",
                                    fontWeight: "bold"
                                }}
                            >
                                Protegemos toda la información que nos proporcionas.
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize:  "20px",
                                    marginBottom: "30px",
                                    fontWeight: "bold"
                                }}
                            >
                                <a
                                    style={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        color: colors.blanco,
                                        marginRight: "5px"
                                    }}
                                    href={pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Ver en PDF nuestro aviso de privacidad,
                                </a>
                                <a
                                    style={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        color: colors.blanco,
                                    }}
                                    href={pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download="AVISO_DE_PRIVACIDAD_TZEDAKA.pdf"
                                >
                                    Descargar aviso de privacidad en PDF

                                </a>
                            </Label>
                        </Col>

                    </Row>
                </div>
                <div >
                    <div
                        style={{
                            marginTop: "20px",
                            color: colors.negro,
                            textAlign: "justify",
                            backgroundColor: "rgba(255,255,255,0.75)",
                        }}
                    >

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col>
                                <Label
                                    style={{
                                        marginTop: "50px",
                                    }}
                                >
                                    A todos nuestros solicitantes de crédito y Clientes (En lo sucesivo “Clientes”):
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{

                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col>
                                <p>
                                    OPERDORA TZEDAKÁ S.AP.I. DE C.V. responsable del uso y protección de sus datos personales da a conocer a usted el presente Aviso de Privacidad, en cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares .(En lo sucesivo “Ley”).
                                </p>
                            </Col>
                        </Row>


                        <Row
                            style={{

                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula I.- Identidad y domicilio del responsable.
                                </Label>
                                <br />
                                <p style={{ marginTop: "15px" }} >
                                    OPERADORA TZEDAKÁ, S.A.P.I DE C.V., en los sucesivo TZEDAKÁ, con domicilio en CALLE VICENTE CORTES #465, LOTE 1, CON CÓDIGO POSTAL 29090, COLONIA SANTA ANA, TUXTLA GUTIÉRREZ, CHIAPAS, es responsable de recabar los datos personales y sensibles del “Cliente”, del manejo de los mismos y de su protección.
                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula II.- Datos personales para realizar el tratamiento.
                                </Label>
                                <br />
                                <p style={{ marginTop: "15px" }} >
                                    TZEDAKÁ recaba datos personales de los solicitantes de crédito (s) y clientes de manera personal, por cualquiera de los medios permitidos por la “Ley”, con la finalidad de identificar a los solicitantes y clientes para poder celebrar contrato de crédito con el mismo, informarle sobre el estatus del crédito, otorgar información respecto a las solicitudes de crédito y contratos celebrados, requerir pagos, contactar al cliente mientras esté vigente la relación contractual para informar el estatus del mismo;  utilizaremos datos personales que TZEDAKÁ considera sensibles como lo son: identificación, contacto y ubicación, referencias laborales y personales, biométricos, características físicas, voz, fotografía, firma física y digital, patrimoniales, financieros, de salud, identificación de dispositivos móviles, geolocalización, así como videograbaciones de validación de identidad, registro autógrafo digital, reconocimiento de compromisos y para la seguridad en nuestras instalaciones.
                                </p>
                                <br />
                                <p>
                                    Acepto el tratamiento de los datos considerados como sensibles descritos con anterioridad _______________________.
                                </p>
                                <br />
                                <p>
                                    Como medidas de seguridad de nuestras instalaciones y para garantizar un ambiente seguro y sano, implementamos en nuestra sucursal cámaras de videograbación que toma audio y video las veinticuatro horas del día. Las imágenes y sonidos tomados por las cámaras de videovigilancia serán utilizados para el resguardo de la seguridad de los “Clientes” y personal que labora en nuestras instalaciones.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    CLAUSULA III.- FINALIDAD DEL TRATAMIENTO.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    Los datos personales que le proporcione a TZEDAKÁ, serán utilizados para la realización de actividades que pudieran dar origen a relaciones jurídicas derivadas de operaciones de créditos; conformar el historial crediticio para fines legales, administrativos, estadísticos, de análisis crediticio, contable, de recuperación de cartera, para ser transferidos a las sociedades de información crediticias a las entidades regulatorias y calificadoras gubernamentales y privadas, para dar cumplimiento y seguimiento a las obligaciones jurídicas que se deriven y sean accesorias de las operaciones de crédito, para cumplir con disposiciones de conocimiento del “Cliente” y prevención de lavado de dinero, dar cumplimiento y seguimiento a los compromisos contractuales y la colaboración con terceros participantes antes, durante y la liquidación del crédito, cesiones, subrogaciones y constitución de fideicomisos. Para la acreditación de su identidad y validación de la información proporcionada, incluyendo la de sus beneficiarios, referencias, obligados solidarios, avales o dadores, según sea el caso, cumplir con regulaciones, políticas y medidas de seguridad física y protección civil, tales como sistemas de video vigilancia, acceso a instalaciones y áreas restringidas, para la actualización de sus datos.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula IV.- Negativa al tratamiento de datos personales.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    Si el titular desea que sus datos personales no sean tratados para los fines secundarios antes mencionados, puede presentar desde este momento un escrito en las oficinas de TZEDAKÁ, manifestando lo anterior. Solicite el formato correspondiente en nuestras oficinas o al correo electrónico <a style={this.state.style.link} href="mailto:operadora@tzedaka.com.mx">operadora@tzedaka.com.mx</a> los medios de contacto que utilizaremos para las finalidades descritas en el presente aviso son: Presencial en sucursal, domicilio o trabajo, telefónico, correo electrónico, SMS, Whatsapp, correo tradicional, páginas web, redes sociales o cualquier otro que usted nos indique. El “Cliente” tiene un plazo de cinco días hábiles para que, de ser el caso, manifieste su negativa para el tratamiento de sus datos personales con respecto a las finalidades que no son necesarias, ni dieron origen a la relación jurídica.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula V.- Transferencias.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    TZEDAKÁ utiliza los datos personales de sus “Clientes” exclusivamente para los fines señalados en el presente Aviso de Privacidad y los conserva por el tiempo que las disposiciones legales señalan.
                                </p>
                                <br />
                                <p>
                                    Le informamos que sus datos personales podrán ser compartidos con terceros nacionales y extranjeros en los casos señalados en el presente Aviso de Privacidad y en el contrato de crédito que se formalice con los requisitos de ley, así también podrá ser compartido con entidades públicas y/o privadas que hayan intervenido en el financiamiento del crédito y/o otorgado cualquier apoyo, así como cualquier autoridad competente que llegara a requerir los datos de manera fundada y motivada, cuando se venda la cartera de créditos, para hacer los requerimientos de pagos a través de despachos de cobranza y cuando así lo requiera por disposición de alguna ley.
                                </p>
                                <br />
                                <p>
                                    El “cliente” acepta de conformidad para que TZEDAKÁ lleve a cabo la transferencia en los supuestos que se prevén en el presente Aviso de Privacidad.
                                </p>
                                <br />
                                <p>
                                    Acepto las transferencias antes enunciadas _______________________.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula VI.- Medios y procedimiento para ejercer los derechos ARCO y para revocar el consentimiento del tratamiento de datos personales.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    El “Cliente” tiene el derecho de acceder, rectificar, cancelar sus datos personales, oponerse o en su caso, revocar el consentimiento al tratamiento de dichos datos que para tal fin haya otorgado previamente a TZEDAKÁ. Para la salvaguarda de sus derechos ARCO y para revocar el consentimiento al tratamiento de datos personales, el “Cliente” podrá solicitarlos en nuestras oficinas o al correo electrónico <a style={this.state.style.link} href="mailto:operadora@tzedaka.com.mx">operadora@tzedaka.com.mx</a> . Es importante que tenga en cuenta que, para revocar el consentimiento del tratamiento de datos personales, no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                                </p>
                                <br />
                                <p>
                                    Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                                </p>
                                <br />
                                <p>
                                    Para dar cumplimiento a la obligación de acceso TZEDAKÁ, pondrá a disposición los datos personales mediante copia simple de sus datos, el titular lo tendrá que solicitar por escrito y le serán entregados de manera gratuita, dentro del plazo de 20 días hábiles, a partir de la fecha de la solicitud.
                                </p>
                                <br />
                                <p>
                                    La respuesta a la solicitud de revocación del consentimiento para el tratamiento de datos personales y demás derechos ARCO, será de 20 días hábiles a partir de la fecha de solicitud.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula VII.- Limitantes del uso o Divulgación de los datos.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    Le informamos que en nuestro sitio web utilizamos cookies, rastreadores GIF, etiquetas de píxel y Google Analytics a través de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula VIII.- Uso de tecnología en Internet.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    Le informamos que en nuestro sitio web utilizamos cookies, rastreadores GIF, etiquetas de píxel y Google Analytics a través de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
                                </p>
                                <br />
                                <p>
                                    Los datos personales que obtenemos de estas tecnologías de rastreo como los mencionados en las líneas que anteceden y adicionando a lo anterior, usted nos puede proporcionar de manera directa en nuestro sitio web, datos de identificación, contacto y ubicación, así como fechas de cumpleaños, mismos que utilizaremos para: Fines de identificación y verificación de datos, informativos, promociones y publicidad, atención y seguimiento de quejas, estadísticos y las demás finalidades descritas en el presente aviso.
                                </p>
                                <br />
                                <p>
                                    Asimismo, le informamos que los datos recabados por TZEDAKÁ mediante el uso de las tecnologías antes mencionadas (cookies), no se transfieren a un tercero.
                                </p>
                            </Col>
                        </Row>



                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula IX.-  Uso de Cookies y Web Beacons.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    TZEDAKÁ utiliza varias tecnologías para mejorar la eficiencia de su sitio web <a style={this.state.style.link} target="_blank" href="https://tzedaka.com.mx/">https://tzedaka.com.mx/</a> Entre estas tecnologías se incluye el uso de cookies (pequeños fragmentos de teCto que los sitios web que visitas envían al navegador) y web beacons  (Imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios). Los “Clientes” pueden, en todo momento, deshabilitar el uso de este tipo de tecnologías en sus equipos de cómputo.
                                </p>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col
                                style={{
                                    marginTop: "30px",
                                }}
                            >
                                <Label
                                    style={this.state.styleLabelTitulo}
                                >
                                    Cláusula X: Contacto y comunicación.
                                </Label>
                                <p style={{ marginTop: "15px" }}>
                                    Cualquier cambio y/o modificación total o parcial al presente Aviso de Privacidad se dará a conocer a nuestros “Clientes” directamente en las sucursales y/o mediante la publicación del mismo en la siguiente página web: <a target="_blank" style={this.state.style.link} href="https://tzedaka.com.mx/aviso-de-privacidad">https://tzedaka.com.mx/aviso-de-privacidad</a>
                                </p>
                            </Col>
                        </Row>


                        <Row
                            style={{
                                borderRadius: "8px",
                                marginRight: "5px",
                                marginLeft: "5px",
                            }}
                        >
                            <Col style={{ marginTop: "30px", }}>
                                <center>
                                    <Label
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            color: "#67677F"
                                        }}
                                    >



                                    </Label>
                                    <br />
                                    <p style={{
                                        marginTop: "15px",
                                        fontSize: "28px",
                                        fontWeight: "bold",
                                        color: "#67677F"
                                    }} >

                                    </p>
                                </center>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default AvisoPrivacidad;