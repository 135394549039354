// import { Component } from "react";
// import "../../assets/css/Notfound404.css";
// import Colors from "../../config/colors.config.js";
// class NotFound404 extends Component {


//     componentDidMount() {
//         document.body.style.backgroundColor = Colors.azul
//     }

//     render() {
//         return (
//             <>
//                 <div>
//                     {/* <header>
//                         <div className="container">
//                             <div id="logo" className="tl-logo" />
//                         </div>
//                     </header> */}
//                     <div className="table mainbox">
//                         <div className="table-cell">
//                             <div className="holder">
//                                 <h2>Página no encontrada</h2>
//                                 <h1
//                                     style={{
//                                         fontFamily: "Lato, sans-serif",
//                                         fontWeight: "100",
//                                         fontSize: "40vmin",
//                                         textAlign: "center",
//                                         lineHeight: "30vh",
//                                         margin:" 6vh 0 10vh 0",
//                                         color: "rgba(255, 255, 255, 0.7)",
//                                         textShadow: "1px 2px 5px rgba(0, 0, 0, 0.5)",   
//                                     }}
//                                 >404</h1>
//                                 <h2>Lo siento, no hay nada que ver aquí.</h2>
//                                 <button
//                                     style={{
//                                         borderRadius: "8px",
//                                         borderColor: "#FFF #FFF #FFF #FFF",
//                                         boxShadow: "none",
//                                         backgroundColor: "#FFF",
//                                         color: Colors.azul,
//                                         marginTop: "10px"
//                                     }}
//                                     onClick={() => {
//                                         window.location.href = "/";
//                                     }}
//                                 >
//                                     Pagina principal
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </>
//         );
//     }
// }

// export default NotFound404;
import { Component } from "react";
import NavBar from "../../components/NavBar";
import colors from "../../config/colors.config.js";
import {
    Row,
    Col,
    Label,    
    Button,
} from "reactstrap"
import img from "../../assets/image/background/003.jpg"
class NotFound404 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isButtonHover:false,
        }

    }
    componentDidMount() {
        
        this.props.isDesktopDevice ?
        document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover;`
        :
        document.body.style = `background: url(${img}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`

    }

    render() {
        return (
            <>
                <NavBar isDesktopDevice={this.props.isDesktopDevice} />
                          
                <div
                    style={{
                        marginTop: "13%",
                        marginRight: this.props.isDesktopDevice ? "20%" : "5px",
                        marginLeft: this.props.isDesktopDevice ? "20%" : "5px",
                        backgroundColor: "rgba(255,255,255,0.45)",
                        borderRadius: "8px"
                    }}
                >
                    <Row >
                        <Col
                            style={{
                                marginTop: "50px",
                            }}
                        >
                            <center>
                                <Label
                                    style={{
                                        fontSize: "56px",
                                        color: colors.azul,
                                        
                                        fontWeight: "bold",

                                    }}
                                >
                                    TZEDAKA
                                </Label>
                            </center>
                        </Col>
                    </Row>
                    {/* <DropdownItem divider /> */}
                    <Row
                        style={{
                            marginTop: "70px",
                        }}
                    >
                        <Col>
                            <center>
                                <Label
                                    style={{
                                        fontSize: this.props.isDesktopDevice ? "35px":"25px",
                                        color: colors.azul,
                                        // color:"#FBB23C",
                                        fontWeight: "bold",

                                    }}
                                >
                                    Página no encontrada

                                </Label>
                            </center>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: this.props.isDesktopDevice ? "50px":"20px",
                        }}
                    >
                        <Col>
                            <center>
                                <Button
                                    style={{
                                        fontSize: "20px",
                                        color: colors.azul,   
                                        backgroundColor:"transparent",                                     
                                        boxShadow: "none",
                                        fontWeight: "bold",
                                        marginBottom:"50px",
                                        textDecoratio:"none",
                                        borderWidth:"3px",
                                        borderColor: this.state.isButtonHover ? `transparent transparent ${colors.azul} transparent` : "transparent",
                                        
                                    }}
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                    onMouseEnter={() => {
                                        this.setState({
                                            isButtonHover:true,
                                        });
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({
                                            isButtonHover:false,
                                        });
                                    }}
                                >
                                    ¿Estas perdido?, has click aquí para ir la pagina principal
                                </Button>
                            </center>
                        </Col>
                    </Row>
                    <Row><Col style={{marginTop:"50px"}} ></Col></Row>

                </div>
                
            </>
        );
    }

}

export default NotFound404;