

const getData = () => {

    let userAgent = navigator.userAgent.toLowerCase();

    let isTablet = false;
    let isMovil = false;
    let isDesktop = false;

    if (userAgent.match(/mobile|android|iphone|ipad/)) {      
      if (userAgent.match(/ipad/)) {        
        isTablet = true        
      } else {        
        isMovil = true;
      }
    } else {      
      isDesktop = true;
    }

    let data = {
        isTablet ,
        isMovil ,
        isDesktop ,
    }

    return data

}

const Device = {
    getData
}

export default Device;