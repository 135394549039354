import React, { useState, useEffect } from 'react';
import imgLogo from "../assets/image/logo/logo_2.png";
import svgLogin from "../assets/image/svgLogin.svg";
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import colors from "../config/colors.config";
import { AccountCircle, Lock, LockOpen, Visibility, VisibilityOff } from "@material-ui/icons";
import usuarioModel from "../services/User.service";
import components from "../tools/components.tool";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import Block from '../components/Block';

const cookies = new Cookies();

const LoginUser = (props) => {
    const [user, setUser] = useState({
        username: "",
        password: ""
    });
    const [mostrarPassword, setMostrarPassword] = useState(false);

    useEffect(() => {
        // const setBodyStyle = () => {
        //     const bodyStyle = props.isDesktopDevice ?
        //         `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover;` :
        //         `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center center;`;

        //     document.body.style = bodyStyle;
        // };

        // setBodyStyle();
        // components.fucusElement("username");

        // // Cleanup
        // return () => {
        //     document.body.style = '';
        // };
        components.fucusElement("username");
    }, []);

    const cambiarDatosUsuario = async (event) => {
        const { value, name } = event.target;
        await setUser({
            ...user,
            [name]: value
        });
    };

    const validarDatosUsuario = async () => {
        const { username, password } = user;
        let esValido = true;

        if (username.length === 0 && password.length === 0) {
            esValido = false;
            toast.warning("Falta por poner el nombre de usuario y contraseña");
            return esValido;
        }

        if (username.length === 0) {
            esValido = false;
            toast.warning("Falta por poner el nombre de usuario");
            return esValido;
        }

        if (password.length === 0) {
            esValido = false;
            toast.warning("Falta por poner la contraseña");
            return esValido;
        }

        return esValido;
    };

    const loguearUsuario = async () => {
        let esValido = await validarDatosUsuario();

        if (!esValido) return;

        const IdToast = toast.loading("Logueado usuario...");
        await components.sleep(1).then(async () => {
            let response = await usuarioModel.loguearUsuario(user);
            console.log(response)
            let { statusUser,status, usuario } = response;

            if (status === 201) {                
                toast.update(IdToast, {
                    render: "Acceso denegado",
                    type: toast.TYPE.WARNING,
                    isLoading: false,
                    closeButton: true,
                    autoClose: 5000,
                });
            }

            if (status === 200) {
                let { token } = usuario;
                cookies.set("usuario", usuario, { path: "/" });
                cookies.set("token", token, { path: "/" });
                toast.update(IdToast, {
                    render: "Usuario logueado",
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    closeButton: true,
                    autoClose: 1100,
                });
                await components.sleep(1.1).then(() => {
                    window.location.href = "/lista-clientes";
                });
            }
        });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "#F4F4F4",
                }}
            >

                <Block height={"10vh"} />
                <Row
                    style={{
                        backgroundColor: "rgba(255,255,255,0.80)",
                        height: "80vh",
                        borderRadius: "8px",
                        width: props.isDesktopDevice ? "70%" : "94%",
                        marginRight: props.isDesktopDevice ? "15%" : "3%",
                        marginLeft: props.isDesktopDevice ? "15%" : "3%",
                        boxShadow: "-10px 0 10px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {props.isDesktopDevice && (
                        <Col
                            style={{
                                borderRadius: "8px 0px 0px 8px",
                            }}
                        >
                            <Row>
                                <Col style={{ textAlign: "center" }} >
                                    <img
                                        style={{
                                            marginTop: "40px",
                                            maxWidth: "80%",
                                            borderRadius: "8px",
                                        }}
                                        src={svgLogin}
                                        alt="No se pudo cargar la imagen"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }} >
                                    <Label
                                        style={{
                                            marginTop: "45px",
                                            fontWeight: "bold",
                                            fontSize: 22,
                                            color: colors.azul
                                        }}
                                    >
                                        CRÉDITOS EN BENEFICIO DE TODOS
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }} >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 18,
                                            color: colors.azul
                                        }}
                                    >
                                        ESTAMOS CONSTRUYENDO ALGO FANTÁSTICO
                                    </Label>
                                </Col>
                            </Row>
                        </Col>
                    )}

                    <Col
                        style={{
                            borderRadius: "0px 8px 8px 0px"
                        }}
                    >
                        <Row>
                            <Col style={{ textAlign: "center" }} >
                                <img
                                    src={imgLogo}
                                    style={{
                                        marginTop: "40px",
                                        maxWidth: "125px"
                                    }}
                                    alt="No se pudo cargar la imagen"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }} >
                                <Label
                                    style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        fontSize: "30px",
                                        color: colors.azul
                                    }}
                                >
                                    Inicio de Sesión
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginRight: props.isDesktopDevice ? "5%" : "",
                                marginLeft: props.isDesktopDevice ? "5%" : "",
                                marginTop: "20px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "cemnte"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: "18px"
                                        }}
                                    >
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: colors.azul,
                                            }}
                                        >
                                            <AccountCircle
                                                style={{
                                                    color: colors.azul
                                                }}
                                            />
                                        </InputGroupText>
                                        <Input
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: colors.azul,
                                                maxWidth: "100%",
                                                boxShadow: "none"
                                            }}
                                            name="username"
                                            id="username"
                                            value={user.username}
                                            type="text"
                                            onChange={async (event) => {
                                                await cambiarDatosUsuario(event);
                                            }}
                                            onKeyDown={async (event) => {
                                                let { key } = event;
                                                if (key === "Enter") {
                                                    loguearUsuario();
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginRight: props.isDesktopDevice ? "5%" : "",
                                marginLeft: props.isDesktopDevice ? "5%" : "",
                                marginTop: "20px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul,
                                            fontSize: "18px"
                                        }}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: colors.azul,
                                            }}
                                        >
                                            {mostrarPassword ? (
                                                <LockOpen
                                                    style={{
                                                        color: colors.azul
                                                    }}
                                                />
                                            ) : (
                                                <Lock
                                                    style={{
                                                        color: colors.azul
                                                    }}
                                                />
                                            )}
                                        </InputGroupText>
                                        <Input
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: colors.azul,
                                                maxWidth: "100%",
                                                boxShadow: "none"
                                            }}
                                            name="password"
                                            id="password"
                                            value={user.password}
                                            type={mostrarPassword ? "text" : "password"}
                                            onChange={async (event) => {
                                                await cambiarDatosUsuario(event);
                                            }}
                                            onKeyDown={async (event) => {
                                                let { key } = event;
                                                if (key === "Enter") {
                                                    loguearUsuario();
                                                }
                                            }}
                                        />
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderColor: colors.azul,
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                setMostrarPassword(!mostrarPassword);
                                            }}
                                        >
                                            {mostrarPassword ? (
                                                <Visibility
                                                    style={{
                                                        color: colors.azul
                                                    }}
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    style={{
                                                        color: colors.azul
                                                    }}
                                                />
                                            )}
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginRight: props.isDesktopDevice ? "5%" : "",
                                marginLeft: props.isDesktopDevice ? "5%" : "",
                                marginTop: "10px"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: colors.gris,
                                        color: colors.blanco,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        borderColor: colors.gris,
                                        width: "45%",
                                    }}
                                    onClick={async () => {
                                        await setUser({
                                            username: "",
                                            password: "",
                                        });
                                        await components.fucusElement("username");
                                    }}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    style={{
                                        backgroundColor: colors.verde_1,
                                        color: colors.blanco,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        borderColor: colors.verde_1,
                                        width: "45%",
                                        marginLeft: "10%"
                                    }}
                                    onClick={async () => {
                                        await loguearUsuario();
                                    }}
                                >
                                    {props.isDesktopDevice ? "Iniciar Sesión" : "Lograrse"}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Block height={"10vh"} />
            </section>
        </>
    );
};

export default LoginUser;
